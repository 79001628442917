<template>
  <div class="item-list-container">
    <div class="row justify-content-between item-list-background-container">
      <div class="col-10">
        <div class="info-container">
          <div class="d-flex align-items-center">
            <p class="title-text"> {{ this.categoryID.split("-")[1] }} </p>
            <p class="sub-title-text" v-if="this.categoryID.split('-')[7]!=''"></p>
            <p> {{ this.categoryID.split("-")[7] }} </p>
          </div>
          <div class="d-flex">
            <p class="sub-title-text">Marketplace: </p>
            <p> {{ this.categoryID.split("-")[0] }} </p>
          </div>
          <div class="d-flex">
            <ul class="list-unstyled">
              <li v-if="families"><p class="sub-title-text">Agrupaciones:</p></li>
              <li v-for="(family, index) in families" :key="family" :index="index">
                <div v-if="family!=''" class="d-flex" >
                  <p class="sub-title-text">-</p>
                  <p class="sub-title-text text-ellipsis"> {{ families[index] }} </p> 
                  <p class="sub-title-text">=</p>
                  <p class="sub-title-text text-ellipsis"> {{ this.categoryID.split("-")[index + 2] }} </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-2 delete-item-cross">
        <button class="delete-item-button">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtklEQVR4nGNgGAUjE2jbBhzStgs4o2cZKEZILUiNtp3/WW27gINEW6Bj639Kxy7gv7at/2V8loANt/W/DFKrY+d/kmgLDGxCRbVtAy5BLbmuYRsoSUiNln2oBNEWELLEgFLD8VliQC3DsYWztq3/ZWQ2MYmAZJ/oUMvlhCzQwBLxgy+IDLBEKDFJmGzDGXDIkWwJMUnRgBJLtG0DTpNcVNj6nyLBAv/jpBV2AWdAeoi2YBQMLwAAPVOaDTPYqJkAAAAASUVORK5CYII="
            @click="onClick(this.categoryID)"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categoryID: String,
    onClick: undefined,
    families: []
  },
};
</script>

<style scoped>
.item-list-container {
  width: 85%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.item-list-background-container {
  background-color: #e9e8e8;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.title-text {
  text-align: start;
  font-size: 20px;
  color: #f18a00;
  padding-right: 6px;
  word-wrap: break-word;
}

.sub-title-text{
  padding-right: 6px;
  word-wrap: break-word;
  text-align: start;
}

.delete-item-cross {
  display: flex;
  text-align: end;
  justify-content: center;
  align-items: center;
}

.delete-item-button{
  border: 0px;
  background: transparent;
}

.text-ellipsis {
  white-space: nowrap; /* Evita saltos de línea */
  overflow: hidden; /* Oculta el texto que se desborda */
  text-overflow: ellipsis; /* Muestra una elipsis (...) al final del texto */
}
</style>
