import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';




createApp(App).use(store).use(router).use(VueSidebarMenuAkahon).use(VueDatepickerUi).use(LoadingPlugin).mount('#app')
