<template>
    <div class="row">
        <div class="insert-inputs-container">
            <div class="row insert-inputs-data">
                <div class="col-12 col-md-8 input-group">
                    <div class="input-group mycustom">
                        <input type="text" class="form-control" v-model="idName" :placeholder="placeholder"
                            aria-describedby="inputGroupPrepend2" v-on:focusin="onHoverNode"
                            v-on:focusout="onHoverNode" />
                    </div>
                </div>
                <div class="col-12 col-md-4 report-type-container">
                    <div class="dropdown">
                        <select class="btn type-dropdown-button" v-model="marketplace"
                            @change="onNodeMarketplaceChange">
                            <option disabled value="">Marketplace</option>
                            <option v-for="item in marketplaces" :key="item">
                                {{ item }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-md-4 families-container" :hidden="reportType != 'node'">
                    <input class="form-control" type="text" placeholder="Titulo del pasillo (obl)" v-model="nickname" />
                    <!-- Titulo del pasillo (obl) ya no es "required" para evitar error 500 -->
                </div>
                <div class="col-12 col-md-4 families-container">
                    <input class="form-control" type="text" placeholder="Valor agrupación 1" v-model="family1" />
                </div>
                <div class="col-12 col-md-4  families-container" :hidden="reportType == 'node'">
                    <input class="form-control" type="text" placeholder="Valor agrupación 2" v-model="family2" />
                </div>
                <div class="col-12 col-md-4 marketplace-input families-container" :hidden="reportType != 'node'">
                    <input class="form-control" type="text" placeholder="Valor agrupación 2" v-model="family2" />
                </div>
                <div class="col-12 col-md-4 marketplace-input families-container" :hidden="reportType == 'node'">
                    <input class="form-control" type="text" placeholder="Valor agrupación 3" v-model="family3" />
                </div>
                <div class="col-12 col-md-4  families-container" :hidden="reportType != 'node'">
                    <input class="form-control" type="text" placeholder="Valor agrupación 3" v-model="family3" />
                </div>
                <div class="col-12 col-md-4 families-container">
                    <input class="form-control" type="text" placeholder="Valor agrupación 4" v-model="family4" />
                </div>
                <!--
                <div class="col-12 col-md-4 families-container">
                    <input
                        class="form-control"
                        type="text"
                        placeholder="Valor agrupación 5"
                        v-model="family5"
                    />
                </div>
                -->

                <div class="row col-12 d-flex justify-content-end align-self-center insert-load-buttons-container">
                    <div class="btn filters-button" @click="showFilters()" v-if="refinements">
                        Mostrar filtros
                    </div>
                    <label class="form-control insert-def-info-button" type="button" v-on:click="this.sendData()">
                        <img src="../assets/white_plus.png" class="csv-download-button-icon" />
                        <p>Añadir a la muestra</p>
                    </label>
                </div>
            </div>
        </div>
        <div>
            <div :hidden="!this.refinementsLoading" class="loadingMsg">Cargando...</div>
            <!-- :hidden="!refinementsLoaded" -->
            <div class="flex-start" :hidden="!refinementsLoaded">
                <p class="inputs-title row col-12">Marca</p>
                <div class="basic-product-sample-options-divider"></div>
                <select class="btn type-dropdown-button-refinement" v-model="selectedBrand" @change="onBrandChange">
                    <option class="dropdown-item" disabled value="">Selecciona una marca</option>
                    <option class="dropdown-item" v-for="brand in refinementsBrands"
                        :value="brand.value.split('||')[1]">{{ brand.name }}</option>
                </select>
                <p class="inputs-title row col-12">Pasillo</p>
                <div class="basic-product-sample-options-divider"></div>
                <select class="btn type-dropdown-button-refinement" v-model="selectedDepartment"
                    @change="onDepartmentChange">
                    <option class="dropdown-item" disabled value="">Selecciona un pasillo</option>
                    <option class="dropdown-item" v-for="departments in refinementsDepartments"
                        :value="departments.value">{{ departments.name }}</option>
                </select>
                <p class="inputs-title row col-12">Precio</p>
                <div class="basic-product-sample-options-divider"></div>
                <div class="display-flex-default">
                    <input v-model="selectedPriceMin" placeholder="Precio mínimo" class="form-control margin-top-price"
                        type="text">
                    <input v-model="selectedPriceMax" placeholder="Precio máximo"
                        class="form-control margin-top-price max-price-input" type="text">
                </div>
            </div>
        </div>
        <p class="optional-schema">Si tienes varios elementos a añadir en el informe, puedes subirlo a partir de la
            plantilla</p>
        <div class="col-12 col-12 input-group margin-final-container">
            <DownloadNodeTemplate :downloadType="this.reportType" />
            <div class="load_csv_button">
                <label class="form-control load-csv-container-input" :for="reportType" type="button">
                    <img src="../assets/upload2.png" class="csv-download-button-icon" />
                    <p>Cargar plantilla</p>
                </label>
                <input :id="reportType" type="file" style="display: none" accept=".csv"
                    v-on:change="this.sendFile($event)" />
            </div>
        </div>
    </div>
</template>

<script>
import DownloadNodeTemplate from '@/components/DownloadNodeTemplate.vue';
import axios from 'axios';

export default {
    components: {
        DownloadNodeTemplate,
    },
    data() {
        return {
            idName: '',
            marketplace: '',
            family1: '',
            family2: '',
            family3: '',
            family4: '',
            family5: '',
            nickname: '',
            file: '',
            refinementsLoading: false,
            refinementsLoaded: false,
            refinementsData: '',
            refinementsBrands: '',
            refinementsDepartments: '',
            refinementsPrice: '',
            selectedBrand: '',
            selectedDepartment: '',
            selectedPriceMin: '',
            selectedPriceMax: '',
            selectedBrandName: '',
            selectedDepartmentName: ''
        }
    },
    props: {
        marketplaces: [],
        saveData: undefined,
        processFile: undefined,
        reportType: '',
        placeholder: '',
        refinements: ''
    },
    methods: {
        onBrandChange(event) {
            const selectedBrandValue = event.target.value;
            const selectedBrand = this.refinementsBrands.find(brand => brand.value.split('||')[1] === selectedBrandValue);
            this.selectedBrandName = selectedBrand ? selectedBrand.name : '';
        },
        onDepartmentChange(event) {
            const selectedDepartmentValue = event.target.value;
            const selectedDepartment = this.refinementsDepartments.find(department => department.value === selectedDepartmentValue);
            this.selectedDepartmentName = selectedDepartment ? selectedDepartment.name : '';
        },
        showFilters() {
            console.log("entrada")
            this.refinementsLoading = true;
            axios.get(`https://baipmarket-prod.brainycommerce.com/api/refinements/?marketplace=${this.marketplace}&keyword=${this.idName}`)
                .then(response => {
                    this.refinementsData = response.data.refinements;
                    this.refinementsBrands = response.data.refinements.brand;
                    this.refinementsDepartments = response.data.refinements.departments;
                    this.refinementsPrice = response.data.refinements.price;
                    if (this.refinementsBrands.length > 7) {
                        this.refinementsBrands.splice(7, 1);
                    }
                })
                .catch(error => {
                    console.log("error: ", error);
                })
                .finally(() => {
                    this.refinementsLoading = false;
                    this.refinementsLoaded = true;
                });
        },
        sendData() {
            this.saveData(this.idName, this.marketplace, this.family1, this.family2, this.family3, this.family4, this.family5, this.nickname, this.selectedBrand, this.selectedDepartment, this.selectedPriceMin, this.selectedPriceMax, this.selectedBrandName, this.selectedDepartmentName),
                this.idName = '',
                this.marketplace = '',
                this.family1 = '',
                this.family2 = '',
                this.family3 = '',
                this.family4 = '',
                this.family5 = '',
                this.nickname = '',
                this.selectedBrand = '',
                this.selectedDepartment = '',
                this.selectedPriceMin = '',
                this.selectedPriceMax = '',
                this.refinementsLoaded = false,
                this.refinementsLoading = false,
                this.refinementsBrands = [],
                this.refinementsDepartments = [],
                this.selectedBrandName = ''
        },
        sendFile(evento) {
            this.processFile(evento),
                this.file = ''
        }
    }

}
</script>

<style scoped>
.row {
    margin: 0;
}

.col-12 {
    padding-left: 0%;
}

.report-type-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.dropdown {
    width: 100%;
}

.type-dropdown-button {
    color: #133641;
    border: solid #133641 1.5px;
    border-radius: 7px;
    background-color: white;
    height: 49px;
    width: 100%;
    text-align: start;
    margin-top: 8px;
}

.form-control {
    margin-top: 8px;
    color: #133641;
    border-color: #133641;
    border-radius: 8px;
    padding: 23px;
}

.marketplace-input {
    padding-right: 0%;
}

.insert-load-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-right: 0px;
}

.filters-button:hover {
    transition: 0.3s;
    -webkit-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
    box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
}

.filters-button {
    background-color: #f18a00;
    font-weight: 700;
    font-size: 18px;
    color: white;
    padding: 10px 25px;
    width: 200px;
    margin-right: 10px;
    height: 50px;
    display: flex;
    border-radius: 8px;
    justify-content: center;
    align-items: center
}

.insert-def-info-button {
    background-color: #f18a00;
    height: 48px;
    border: solid 1px #f18a00;
    min-width: 120px;
    width: 240px;
    border-radius: 5px;
    margin-top: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.insert-def-info-button p {
    font-weight: 700;
    color: white;
}

.type-dropdown-button-refinement {
    margin-top: 5px;
    width: 100%;
    color: #133641;
    border: solid #133641 1.5px;
    border-radius: 7px;
    background-color: white;
    height: 49px;
    text-align: start;
}

.load-csv-container-input {
    background-color: #f18a00;
    border-radius: 5px;
    color: white;
    border: solid 1px #f18a00;
}

.load-csv-container-input p {
    font-weight: 700;
}

.insert-inputs-container {
    padding-top: 0px;
    border-radius: 15px;
    background-color: rgb(243, 243, 243);
}

.insert-inputs-data {
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}

.load_csv_button {
    margin-right: 15px;
}

.margin-final-container {
    padding-left: 15px !important;
    padding-bottom: 15px !important;
}

.basic-product-sample-options-divider {
    border-top: solid rgb(223, 221, 221) 1.5px;
    border-radius: 3px;
    display: grid;
    width: 90%;
    margin-top: 15px;
    margin-bottom: 8px;
}

.inside-basic-product-sample-options-divider {
    width: 300px;
    border-top: solid rgb(223, 221, 221) 1.5px;
    border-radius: 3px;
    display: grid;
}

.optional-schema {
    margin-top: 8px;
    color: #696868;
    text-align: start;
}
</style>