<template>
  <div>
    <div class="toast">
      <div class="toast-header">{{ errorTitle }}</div>
      <div class="toast-body">{{ errorDescription }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorTitle: String,
    errorDescription: String,
  },
};
</script>

<style>
.toast {
  position: absolute;
  bottom: 50px;
  z-index: 1;
  max-width: 500px;
  left: 50%;
  min-width: 500px;
  transform: translate(-50%, -0%);
}

.toast-header {
  background-color: #f44848;
  color: white;
  font-weight: 700;
  text-align: center;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 19px;
}

.toast-body {
  background-color: rgb(245, 245, 245);
}

.fade {
  transition: opacity 0.15s linear;
}
</style>
