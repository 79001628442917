<template>
    <input class="form-control" type="text" v-bind:placeholder=placeholder v-bind:v-model=model>
</template>

<script>
export default {
    props: {
        placeholder: String,
        model: String,
    }
}
</script>

<style scoped>
.form-control{
    color: #133641;
    border-color: #133641;
    border-radius: 8px;
    padding: 23px;
}
</style>