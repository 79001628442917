<template>
    <div class="home">
        <ErrorToast :errorTitle="this.errorTitle" :errorDescription="this.errorDescription" />
        <div class="basic-inputs vl-parent">
            <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
            <div class="row masterrow">
                <div class="col-12 col-md-7 inputs-container">

                    <p class="inputs-title-less">Información general</p>
                    <div class="basic-product-sample-options-divider"></div>
                    <select class="type-dropdown-button" v-model="selectedReportId" @change="filterNicknames">
                        <option disabled value="">Selecciona un informe</option>
                        <option v-for="reportId in uniqueReportIds" :key="reportId" :value="reportId">
                            {{ reportId }}
                        </option>
                    </select>
                    <form @submit.prevent="getFormValues" class="report-form-container">
                        <input type="hidden" name="csrfmiddlewaretoken" :value="csrfToken" />
                        <div class="row">



                            <p class="inputs-title row col-12">Definición de la muestra</p>
                            <div class="basic-product-sample-options-divider"></div>
                            <div class="col-12 marketplace-input">
                                <div class="product-sample-title-container margin-product-sample-families">
                                    <p class="product-sample-title">¿Por qué agrupaciones quieres clasificar la muestra?</p>
                                    <div class="help-icon date-selector-margin" data-toggle="tooltip" data-placement="right"
                                        title="Puedes agrupar los elementos de la muestra por hasta 5 agrupaciones. Supongamos un informe en el que se estudian los pasillos de ukeleles, guitarras y armónicas. Una agrupación posible sería 'materiales' (madera o aluminio). Otra posible agrupación sería 'tamaño' (grande o pequeño). Pon aquí el título de estas agrupaciones. En el ejemplo sería 'materiales' y 'tamaño'.">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4 families-container">
                                        <input class="form-control margin-families-form" type="text"
                                            placeholder="Agrupación 1" v-model="family1Name" />
                                    </div>
                                    <div class="col-12 col-md-4 families-container">
                                        <input class="form-control margin-families-form" type="text"
                                            placeholder="Agrupación 2" v-model="family2Name" />
                                    </div>
                                    <div class="col-12 col-md-4 marketplace-input families-container">
                                        <input class="form-control margin-families-form" type="text"
                                            placeholder="Agrupación 3" v-model="family3Name" />
                                    </div>
                                    <div class="col-12 col-md-4 families-container">
                                        <input class="form-control margin-families-form" type="text"
                                            placeholder="Agrupación 4" v-model="family4Name" />
                                    </div>
                                    <!--
                    <div class="col-12 col-md-4 families-container">
                      <input
                          class="form-control margin-families-form"
                          type="text"
                          placeholder="Agrupación 5"
                          v-model="family5Name"
                      />
                    </div>
                    -->
                                </div>
                            </div>
                            <div class="product-sample-title-container">
                                <p class="product-sample-title">¿Cuál es el origen de la muestra?</p>
                                <div class="help-icon date-selector-margin" data-toggle="tooltip" data-placement="right"
                                    title="Los ASINs que compongan la muestra para el estudio pueden provenir de una lista de pasillos (tomaremos los ASINs del BSR), de una lista de keywords (tomaremos los ASINs de la página de resultados) y/o de una lista plana de ASINs que introduzcas directamente.">
                                </div>
                            </div>
                            <div class="basic-product-sample-toggles">
                                <SampleToggle :toggleActive="checkboxNodes" toggleText="Pasillo" toggleName="checkboxNodes"
                                    :toggleCheckbox="this.toggleCheckbox" />
                                <input class="form-control sub-text-input margin-8-top" type="text"
                                    placeholder="ASINs por BSR de pasillo" v-model="nodesSampleAmount"
                                    :hidden="!checkboxNodes" />
                                <div class="row align-items-center" :hidden="!checkboxNodes">
                                    <p class="title-basic-product-sample-text">¿Qué pasillos quieres analizar?</p>
                                </div>
                                <SampleDefinitionComponent class="padding-20-left" :hidden="!checkboxNodes"
                                    :marketplaces="marketplaces" :saveData="this.insertNode"
                                    :processFile="this.nodeParseFile" placeholder="Insertar ID del pasillo"
                                    reportType="node" />
                                <div class="basic-product-sample-options-divider padding-20-left margin-10-bottom"
                                    :hidden="!checkboxNodes"></div>
                                <SampleToggle :toggleActive="checkboxKeywords" toggleText="Keyword"
                                    toggleName="checkboxKeywords" :toggleCheckbox="this.toggleCheckbox" />
                                <div class="keywords-help-icon-container margin-8-top" :hidden="!checkboxKeywords">
                                    <input class="form-control sub-text-input" type="text" placeholder="Páginas por keyword"
                                        v-model="keywordsSampleAmount" :hidden="!checkboxKeywords" />
                                    <div class="help-icon date-selector-margin" data-toggle="tooltip" data-placement="right"
                                        title="Ten en cuenta que cada página de keywords incluye 50 ASINs (o menos si no hay tantos resultados)"
                                        :hidden="!checkboxKeywords"></div>
                                </div>
                                <div class="row align-items-center" :hidden="!checkboxKeywords">
                                    <p class="title-basic-product-sample-text">¿Qué keywords quieres analizar?</p>
                                </div>
                                <SampleDefinitionComponent class="padding-20-left" :hidden="!checkboxKeywords"
                                    :marketplaces="marketplaces" :saveData="this.insertKeyword"
                                    :processFile="this.keywordsParseFile" placeholder="Insertar keyword"
                                    reportType="keyword" />
                                <div class="basic-product-sample-options-divider padding-20-left margin-10-bottom"
                                    :hidden="!checkboxKeywords"></div>
                                <SampleToggle :toggleActive="checkboxAsins" toggleText="Lista de ASINs"
                                    toggleName="checkboxAsins" :toggleCheckbox="this.toggleCheckbox" />
                                <div class="row align-items-center" :hidden="!checkboxAsins">
                                    <p class="title-basic-product-sample-text">¿Qué ASINs quieres analizar?</p>
                                </div>
                                <SampleDefinitionComponent class="padding-20-left" :hidden="!checkboxAsins"
                                    :marketplaces="marketplaces" :saveData="this.insertAsin"
                                    :processFile="this.asinsParseFile" placeholder="Insertar ASIN" reportType="asin" />
                                <div class="basic-product-sample-options-divider padding-20-left margin-10-bottom"
                                    :hidden="!checkboxAsins"></div>
                            </div>
                            <div class="insert-input-container col-12">
                                <!--
                  <div class="product-sample-title-container">
                    <p class="product-sample-title">¿Qué elementos (pasillos, keywords y/o ASINs) quieres incorporar?</p>
                  </div>
                  <div class="row align-items-center report-parent-type-container">
                    <div class="col-12 col-md-4 report-type-container">
                      <div class="dropdown">
                        <select class="btn type-dropdown-button" v-model="reportModule" @change="onModuleChange">
                          <option class="dropdown-item" value="nodes" selected>Pasillos</option>
                          <option class="dropdown-item" value="keywords" >Keywords</option>
                          <option class="dropdown-item" value="asins" >ASINs</option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="help-icon sample-help"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Recuerda que en caso de categorías es obligatorio añadir un apodo (Nombre que define la categoría) de cada una de ellas"
                    ></div>
                  </div>
                  <SampleDefinitionComponent  :hidden="reportModule!='keywords'" :marketplaces="marketplaces" :saveData="this.insertKeyword" :processFile="this.keywordsParseFile" placeholder="Insertar keyword" reportType="keyword"/>
                  <SampleDefinitionComponent  :hidden="reportModule!='asins'" :marketplaces="marketplaces" :saveData="this.insertAsin" :processFile="this.asinsParseFile" placeholder="Insertar ASIN" reportType="asin"/>
                  -->
                            </div>

                            <div class="create-report">
                                <div class="help-icon" data-toggle="tooltip" data-placement="right"
                                    title="Token necesario de autentificación, en caso de no tener un token activo, comuníquese con el equipo de desarrollo">
                                </div>
                                <div class="col-12 col-md-6 name-input">
                                    <input class="form-control" type="text" placeholder="Token de usuario"
                                        v-model="userToken" required />
                                </div>
                                <button class="btn create-report-button" @click="modalShow()">
                                    Crear informe
                                </button>
                            </div>
                        </div>
                    </form>
                    <p class="create-report-help">
                        ¿Necesitas ayuda? Escríbenos a developers@brainycommerce.com 😄
                    </p>
                </div>
                <div class="inputs-list-master-main-container">

                    <div v-if="!this.nodesListData.length && !this.keywordsListData.length && !this.asinsListData.length"
                        class="no-data-div"></div>
                    <ul v-show="this.nodesListData.length || this.keywordsListData.length || this.asinsListData.length"
                        class="sample-list">
                        <p v-show="this.nodesListData.length" class="nodes-title-list">
                            Nodos
                        </p>
                        <li v-show="this.nodesListData.length">
                            <ItemList v-for="item in nodesListData" :key="item" :categoryID="item"
                                :families="[this.family1Name, this.family2Name, this.family3Name, this.family4Name, this.family5Name]"
                                :onClick="this.onDeleteNode"></ItemList>
                        </li>
                        <p v-show="this.keywordsListData.length" class="nodes-title-list">
                            Keywords
                        </p>
                        <li v-show="this.keywordsListData.length">
                            <ItemList v-for="item in keywordsListData" :key="item" :categoryID="item"
                                :families="[this.family1Name, this.family2Name, this.family3Name, this.family4Name, this.family5Name]"
                                :onClick="this.onDeleteKeyword"></ItemList>
                        </li>
                        <p v-show="this.asinsListData.length" class="nodes-title-list">
                            ASIN's
                        </p>
                        <li v-show="this.asinsListData.length">
                            <ItemList v-for="item in asinsListData" :key="item" :categoryID="item"
                                :families="[this.family1Name, this.family2Name, this.family3Name, this.family4Name, this.family5Name]"
                                :onClick="this.onDeleteAsin"></ItemList>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
        <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="mi-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title titulo-modal">¿Generar reporte?</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-default modal-font-button" @click="createExperiment()"
                            id="modal-btn-si">Si</button>
                        <button type="button" class="btn modal-color-button" @click="modalClose"
                            id="modal-btn-no">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="success-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title titulo-modal">Reporte subido</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>El reporte se ha generado correctamnete y se encuentra almacenado en nuestro sistema, si
                            necesitas información de cara al reporte contacta con el equipo de desarrollo.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" @click="downloadGenerateJSON"
                            id="modal-btn-no">Descargar JSON generado</button>
                        <button type="button" class="btn modal-color-button" @click="successModalClose"
                            id="modal-btn-no">Perfecto</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import ReportBasicInput from '@/components/ReportBasicInput.vue';
import ReportInsertInput from '@/components/ReportInsertInput.vue';
import DownloadNodeTemplate from '@/components/DownloadNodeTemplate.vue';
import SampleDefinitionComponent from '@/components/SampleDefinitionComponent'
import ItemList from '@/components/ItemList.vue';
import MailItemList from '@/components/MailItemList.vue';
import SettleDatesList from '@/components/SettleDatesList.vue';
import ErrorToast from '@/components/ErrorToast.vue';
import SampleToggle from '@/components/sampleToggle.vue';
import SampleToggleActive from '@/components/sampleToggleActive.vue';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import VueDatepickerUi from 'vue-datepicker-ui';
import { Switch } from '@headlessui/vue';
import { mapActions } from 'vuex';
import axios from 'axios';
import Papa from 'papaparse';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
export default {
    name: 'HomeView',
    components: {
        ReportBasicInput,
        ReportInsertInput,
        ItemList,
        MailItemList,
        SettleDatesList,
        ErrorToast,
        Datepicker: VueDatepickerUi,
        Switch,
        SampleToggle,
        SampleToggleActive,
        DownloadNodeTemplate,
        SampleDefinitionComponent
    },
    created() {
        const dateHour = new Date().getHours();
        if (6 <= dateHour && dateHour < 14) {
            this.title = 'Buenos días ';
        } else if (14 <= dateHour && dateHour < 21) {
            this.title = 'Buenas tardes ';
        } else {
            this.title = 'Buenas noches ';
        }
    },
    mounted() {
        this.fetchReports();
    },
    data() {
        return {
            stopLoading: false,
            title: 0,
            csrfToken: '',
            userToken: '',
            nodesListData: [],
            keywordsListData: [],
            asinsListData: [],
            nodesObject: [],
            keywordsObject: [],
            asinsObject: [],
            dataFromBackend: [],
            family1Name: '',
            family2Name: '',
            family3Name: '',
            family4Name: '',
            family5Name: '',
            checkboxNodes: false,
            nodesSampleAmount: '',
            checkboxKeywords: false,
            keywordsSampleAmount: '',
            checkboxAsins: false,
            hiddeNode: false,
            hiddeKeywords: false,
            modalTitle: '',
            modalDescription: '',
            errorTitle: '',
            errorDescription: '',
            selectedReportId: '',
            generatedJSON: '',
            marketplaces: [
                'AE', 'AU', 'BE', 'BR', 'CA', 'DE', 'EG', 'ES', 'FR', 'IN', 'IT', 'JP', 'COM.MX', 'NL', 'PL', 'SA', 'SE', 'SG', 'TR', 'UK', 'COM',
            ],
            familiesValues:[this.family1Name, this.family2Name, this.family3Name, this.family4Name, this.family5Name]
        };
    },
    computed: {
        uniqueReportIds() {
            return [...new Set(this.dataFromBackend.map(item => item[0]))];
        },
    },
    methods: {
        fetchReports() {
            axios.get('https://baipmarket-prod.brainycommerce.com/api/reports/')
                .then(response => {
                    this.dataFromBackend = response.data.data
                    console.log(response);
                })
        },
        toggleCheckbox(endpoint) {
            console.log(endpoint);
            switch (endpoint) {
                case 'checkboxNodes':
                    this.checkboxNodes = !this.checkboxNodes;
                    this.$emit('setCheckboxVal', this.checkboxNodes);
                    break;
                case 'checkboxKeywords':
                    this.checkboxKeywords = !this.checkboxKeywords;
                    this.$emit('setCheckboxVal', this.checkboxKeywords);
                    break;
                case 'checkboxAsins':
                    this.checkboxAsins = !this.checkboxAsins;
                    this.$emit('setCheckboxVal', this.checkboxAsins);
                    break;
                case 'checkboxSalesPriceHistory':
                    this.checkboxSalesPriceHistory = !this.checkboxSalesPriceHistory;
                    this.$emit('setCheckboxVal', this.checkboxSalesPriceHistory);
                    break;
                case 'checkboxSettleDates':
                    this.checkboxSettleDates = !this.checkboxSettleDates;
                    this.$emit('setCheckboxVal', this.checkboxSettleDates);
                    break;
                case 'checkboxExtraProduct':
                    this.checkboxExtraProduct = !this.checkboxExtraProduct;
                    this.$emit('setCheckboxVal', this.checkboxExtraProduct);
                    break;
                case 'checkboxBullets':
                    this.checkboxBullets = !this.checkboxBullets;
                    this.$emit('setCheckboxVal', this.checkboxBullets);
                    break;
                case 'checkboxTexto':
                    this.checkboxTexto = !this.checkboxTexto;
                    this.$emit('setCheckboxVal', this.checkboxTexto);
                    break;
                case 'checkboxAtributos':
                    this.checkboxAtributos = !this.checkboxAtributos;
                    this.$emit('setCheckboxVal', this.checkboxAtributos);
                    break;
                case 'checkboxBuybox':
                    this.checkboxBuybox = !this.checkboxBuybox;
                    this.$emit('setCheckboxVal', this.checkboxBuybox);
                    break;
                case 'checkboxReasonBuybox':
                    this.checkboxReasonBuybox = !this.checkboxReasonBuybox;
                    this.$emit('setCheckboxVal', this.checkboxReasonBuybox);
                    break;
                case 'checkboxReviews':
                    this.checkboxReviews = !this.checkboxReviews;
                    this.$emit('setCheckboxVal', this.checkboxReviews);
                    break;
                case 'checkboxSummary':
                    this.checkboxSummary = !this.checkboxSummary;
                    this.$emit('setCheckboxVal', this.checkboxSummary);
                    break;
                case 'checkboxComments':
                    this.checkboxComments = !this.checkboxComments;
                    this.$emit('setCheckboxVal', this.checkboxComments);
                    break;
                case 'checkboxNotify':
                    this.checkboxNotify = !this.checkboxNotify;
                    this.$emit('setCheckboxVal', this.checkboxNotify);
                    break;
                case 'checkboxCoupons':
                    this.checkboxCoupons = !this.checkboxCoupons;
                    this.$emit('setCheckboxVal', this.checkboxCoupons);
                    break;
                case 'checkboxKeywordSearchHistory':
                    this.checkboxKeywordSearchHistory =
                        !this.checkboxKeywordSearchHistory;
                    this.$emit('setCheckboxVal', this.checkboxKeywordSearchHistory);
                    break;
            }
        },
        insertNode(idName, marketplace, Family1, Family2, Family3, Family4, Family5, Nickname) {
            if (idName != '' && Nickname != '' && marketplace != '') {
                if (!this.nodesListData.some((x) => idName === x)) {
                    this.nodesListData.push(marketplace + '-' + idName + '-' + Family1 + '-' + Family2 + '-' + Family3 + '-' + Family4 + '-' + Family5 + '-' + Nickname);
                }
            } else {
                this.errorAddingNode();
            }
        },
        insertKeyword(idName, marketplace, Family1, Family2, Family3, Family4, Family5) {
            if (idName != '' && marketplace != '') {
                if (!this.keywordsListData.some((x) => idName === x)) {
                    this.keywordsListData.push(marketplace + '-' + idName + '-' + Family1 + '-' + Family2 + '-' + Family3 + '-' + Family4 + '-' + Family5);
                }
            } else {
                this.errorAddingNode();
            }
        },
        insertAsin(idName, marketplace, Family1, Family2, Family3, Family4, Family5) {
            if (idName != '') {
                if (!this.asinsListData.some((x) => idName === x)) {
                    this.asinsListData.push(marketplace + '-' + idName + '-' + Family1 + '-' + Family2 + '-' + Family3 + '-' + Family4 + '-' + Family5);
                }
            }
        },
        nodeParseFile(event) {
            const file = event.target.files[0];
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                encoding: "ISO-8859-1",
                complete: function (results) {
                    this.content = results;
                    this.parsed = true;
                    this.content.data.forEach((idObject) => {
                        if (idObject.IDNodo != undefined && idObject.Marketplace != undefined && idObject.Familia1 != undefined && idObject.Familia2 != undefined && idObject.Familia3 != undefined && idObject.Familia4 != undefined && idObject.Familia5 != undefined && idObject.Nickname != undefined && idObject.Nickname != "") {
                            if (!this.nodesListData.some((x) => idObject.Marketplace + '-' + idObject.IDNodo + '-' + idObject.Familia1 + '-' + idObject.Familia2 + '-' + idObject.Familia3 + '-' + idObject.Familia4 + '-' + idObject.Familia5 + '-' + idObject.Nickname === x)) {
                                this.nodesListData.push(idObject.Marketplace + '-' + idObject.IDNodo + '-' + idObject.Familia1 + '-' + idObject.Familia2 + '-' + idObject.Familia3 + '-' + idObject.Familia4 + '-' + idObject.Familia5 + '-' + idObject.Nickname);
                            }
                        } else {
                            this.errorImportCSV();
                        }
                    });
                    this.nodesListData = JSON.parse(JSON.stringify(this.nodesListData));
                }.bind(this),
            });
            document.getElementById('file').value = '';
        },
        keywordsParseFile(event) {
            const file = event.target.files[0];
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                encoding: "ISO-8859-1",
                complete: function (results) {
                    this.content = results;
                    this.parsed = true;
                    this.content.data.forEach((idObject) => {
                        if (idObject.Keyword != undefined && idObject.Marketplace != undefined && idObject.Familia1 != undefined && idObject.Familia2 != undefined && idObject.Familia3 != undefined && idObject.Familia4 != undefined && idObject.Familia5 != undefined) {
                            if (!this.keywordsListData.some((x) => idObject.Marketplace + '-' + idObject.Keyword + '-' + idObject.Familia1 + '-' + idObject.Familia2 + '-' + idObject.Familia3 + '-' + idObject.Familia4 + '-' + idObject.Familia5 + '-' + idObject.Nickname === x)) {
                                this.keywordsListData.push(idObject.Marketplace + '-' + idObject.Keyword + '-' + idObject.Familia1 + '-' + idObject.Familia2 + '-' + idObject.Familia3 + '-' + idObject.Familia4 + '-' + idObject.Familia5);
                            }
                        } else {
                            this.errorImportCSV();
                        }
                    });
                    this.keywordsListData = JSON.parse(JSON.stringify(this.keywordsListData));
                }.bind(this),
            });
        },
        asinsParseFile(event) {
            const file = event.target.files[0];
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                encoding: "ISO-8859-1",
                complete: function (results) {
                    this.content = results;
                    this.parsed = true;
                    this.content.data.forEach((idObject) => {
                        if (idObject.ASIN != undefined && idObject.Marketplace != undefined && idObject.Familia1 != undefined && idObject.Familia2 != undefined && idObject.Familia3 != undefined && idObject.Familia4 != undefined && idObject.Familia5 != undefined) {
                            if (!this.asinsListData.some((x) => idObject.Marketplace + '-' + idObject.ASIN + '-' + idObject.Familia1 + '-' + idObject.Familia2 + '-' + idObject.Familia3 + '-' + idObject.Familia4 + '-' + idObject.Familia5 + '-' + idObject.Nickname === x)) {
                                this.asinsListData.push(idObject.Marketplace + '-' + idObject.ASIN + '-' + idObject.Familia1 + '-' + idObject.Familia2 + '-' + idObject.Familia3 + '-' + idObject.Familia4 + '-' + idObject.Familia5);
                            }
                        } else {
                            this.errorImportCSV();
                        }
                    });
                    this.asinsListData = JSON.parse(JSON.stringify(this.asinsListData));
                }.bind(this),
            });
        },
        errorImportCSV() {
            this.errorTitle = 'Formato de CSV no válido';
            this.errorDescription =
                'Es necesario que el CSV tenga una estructura determinada, recuerda validar la estructura y que el Nickname/Nombre de pasillo no sea vacío en caso de añadir un pasillo';
            $('.toast').toast({ delay: 5000 });
            $('.toast').toast('show');
        },
        errorAddingNode() {
            this.errorTitle = 'Algún campo no es correcto';
            this.errorDescription =
                'Recuerda validar la estructura y que el Nickname/Nombre de pasillo no sea vacío al añadir un pasillo';
            $('.toast').toast({ delay: 5000 });
            $('.toast').toast('show');
        },
        onDeleteNode(category_id) {
            console.log(category_id);
            this.nodesListData = this.nodesListData.filter((x) => x !== category_id);
        },
        onDeleteKeyword(keyword) {
            this.keywordsListData = this.keywordsListData.filter((x) => x !== keyword);
        },
        onDeleteAsin(asin) {
            this.asinsListData = this.asinsListData.filter((x) => x !== asin);
        },
        onDeleteMail(mail) {
            this.mailsToSend = this.mailsToSend.filter((x) => x !== mail);
        },
        onNodeMarketplaceChange() {
            var data = '';
            $('select option:selected').each(function () {
                $('#nodeMarketplaceButtton').html($(this).text());
                console.log($(this).text());
                data = $(this).text();
            });
            this.nodeMarketplace = data;
        },
        onKeywordsMarketplaceChange() {
            var data = '';
            $('select option:selected').each(function () {
                $('#keywordMarketplaceButtton').html($(this).text());
                console.log($(this).text());
                data = $(this).text();
            });
            this.keywordMarketplace = data;
        },
        onAsinMarketplaceChange() {
            var data = '';
            $('select option:selected').each(function () {
                $('#asinMarketplaceButtton').html($(this).text());
                console.log($(this).text());
                data = $(this).text();
            });
            this.asinMarketplace = data;
        },
        downloadGenerateJSON() {
            const contenidoJSONString = JSON.stringify(this.generatedJSON);
            const blob = new Blob([contenidoJSONString], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'reporte_generado.json';
            a.click();
            URL.revokeObjectURL(url);
        },

        abrirDocumentacionNuevaPestana() {
            const nuevaURL = 'https://www.notion.so/Alta-de-Informes-96b2c113d01b41d8aa35d5b3923d1706?pvs=4';
            // Abre una nueva pestaña con la URL
            window.open(nuevaURL);
        },
        createExperiment() {
            const report = {
                report_id: this.selectedReportId,
                node_list: this.nodesObject,
                keyword_list: this.keywordsObject,
                asin_list: this.asinsObject,
                add_experiments: true
            }

            this.nodesListData.forEach((x, indice) => {
                let obj = { marketplace: '', category_id: '', Family1: '', Family2: '', Family3: '', Family4: '', Family5: '', nickname: '' };
                let data = x.split('-');
                obj.marketplace = data[0];
                obj.category_id = data[1];
                obj.nickname = data[7];
                this.familiesValues.forEach((x, indice) => {
                    obj['Family' + (indice + 1).toString()] = data[indice + 2]
                })
                for (const key in obj) {
                    if (obj.hasOwnProperty(key) && obj[key] === '') {
                        delete obj[key];
                    }
                }
                this.nodesObject.splice(indice, 0, obj);
            })
            //Creación del objeto de keywords
            this.keywordsListData.forEach((x, indice) => {
                let obj = { marketplace: '', keyword: '', Family1: '', Family2: '', Family3: '', Family4: '', Family5: '' };
                let data = x.split('-');
                obj.marketplace = data[0];
                obj.keyword = data[1];
                this.familiesValues.forEach((x, indice) => {
                    obj['Family' + (indice + 1).toString()] = data[indice + 2]
                })
                for (const key in obj) {
                    if (obj.hasOwnProperty(key) && obj[key] === '') {
                        delete obj[key];
                    }
                }
                this.keywordsObject.splice(indice, 0, obj);
            })
            //Creación del objeto de asins
            this.asinsListData.forEach((x, indice) => {
                let obj = { marketplace: '', ASIN: '', Family1: '', Family2: '', Family3: '', Family4: '', Family5: '' };
                let data = x.split('-');
                obj.marketplace = data[0];
                obj.ASIN = data[1];
                this.familiesValues.forEach((x, indice) => {
                    obj['Family' + (indice + 1).toString()] = data[indice + 2]
                })
                for (const key in obj) {
                    if (obj.hasOwnProperty(key) && obj[key] === '') {
                        delete obj[key];
                    }
                }
                this.asinsObject.splice(indice, 0, obj);
            })

            const jsonString = JSON.stringify(report, null, 2);
            console.log(jsonString);

            var conf_json = report;
            var json = JSON.stringify(conf_json);
            console.log(json);
            $("#mi-modal").modal('hide');
            //const url = 'https://baipmarket-dev.brainycommerce.com/api/reports/';
            const url = 'https://baipmarket-prod.brainycommerce.com/api/reports/';
            //const url = 'http://127.0.0.1:8000/api/report/';
            console.log("x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x")
            var csrftoken = undefined
            const value = `; ${document.cookie}`;
            console.log(value)
            const parts = value.split(`; csrftoken=`);
            if (parts.length === 2) csrftoken = parts.pop().split(';').shift();
            console.log(csrftoken)
            console.log("x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x")
            axios.defaults.withCredentials = true;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + this.userToken,
                    'X-CSRFToken': csrftoken // Agrega el token al encabezado
                },
                credentials: 'include',
                body: JSON.stringify({ 'report': conf_json })
            };
            return fetch(url, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Error en la modificacion del reporte');
                    }
                    return response.json();
                })
                .then(data => {
                    // Manejar el JSON devuelto, si es necesario
                    console.log(data);
                    this.successModalShow(); // Mostrar el modal de éxito
                })
                .catch(error => {
                    this.errorTitle = error.message;
                    this.errorDescription = "Consulte la consola para mas informacion sobre este error";
                    $('.toast').toast({ delay: 7000 });
                    $('.toast').toast('show');
                    console.error(error);
                });


            this.successModalShow();
        },
        modalShow() {
            if (this.userToken != '')
                $("#mi-modal").modal('show');
        },
        modalClose() {
            $("#mi-modal").modal('hide');
        },
        successModalShow() {
            $("#success-modal").modal('show');
        },
        successModalClose() {
            $("#success-modal").modal('hide');
        }







    }
}
</script>
  
<style scoped>
.row {
    margin: 0;
}

.home {
    padding-top: 4%;
    padding-left: 4%;
    padding-right: 4%;
    height: 100vh;
    overflow-y: scroll;
}

.col-12 {
    padding-left: 0%;
}

.name-input {
    padding-left: 0%;
}

.marketplace-input {
    padding-right: 0%;
}

.inputs-container {
    max-height: 93vh;
    padding-left: 0% !important;
    padding-right: 0%;
}

.inputs-title {
    text-align: start;
    font-size: 23px;
    font-weight: 800;
    padding-top: 30px;
    padding-bottom: 0px;
}

.inputs-title-less {
    text-align: start;
    font-size: 23px;
    font-weight: 800;
    padding-top: 10px;
    padding-bottom: 0px;
}

.inputs-title-no-padding {
    text-align: start;
    font-size: 23px;
    font-weight: 800;
    padding-bottom: 0px;
}

.inputs-sub-title {
    text-align: start;
    font-size: 18px;
    font-weight: 800;
    padding-top: 15px;
    padding-bottom: 0px;
}

.insert-input-container {
    padding-right: 0%;
    padding-left: 0%;
    margin-top: 0px;
}

.report-form-container {
    padding-top: 20px;
}

.form-control {
    color: #133641;
    border-color: #133641;
    border-radius: 8px;
    padding: 23px;
}

.inputs-list-master-main-container {
    background-color: #f8f8f8;
    width: 27vw;
    border-radius: 7px;
    min-height: 79vh;
    max-height: 600px;
    position: fixed;
    right: 4%;
    margin-top: 20px;
    overflow-y: auto;
    list-style: none;
}

.inputs-list-master-container {
    height: 79vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.inputs-list-master-container ul {
    height: calc(100% - 20px);
    overflow-y: auto;
}

ul {
    list-style: none;
    /* Elimina los puntos de la lista */
}

.masterrow {
    margin-right: 0px;
    margin-left: 0px;
    display: flex;
    justify-content: space-between;
}

.home {
    height: 100vh;
}

.title {
    text-align: start;
    font-size: 30px;
    font-weight: 800;
    padding-bottom: 20px;
}

.date-selector {
    display: flex;
    float: left;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}

.date-selector-row {
    margin-top: 10px;
}

.date-selector-margin {
    margin-left: 2%;
    width: 100%;
}

@media (min-width: 1299px) {
    .toggle-group-colum {
        margin-left: 3%;
    }
}

.create-report {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 45px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.create-report-help {
    font-weight: 600;
    text-align: center;
    padding-bottom: 35px;
    text-decoration: dashed;
}

.create-report-button {
    background-color: #f18a00;
    font-weight: 700;
    font-size: 18px;
    color: white;
    padding: 10px 25px;
    width: fit-content;
    height: 50px;
    margin-left: 15px;

}

.create-report-button:hover {
    transition: 0.3s;
    -webkit-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
    box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
}

.btn:focus {
    outline: none;
    box-shadow: none;
    color: #f18a00;
}

.no-data-div {
    background-image: url('../assets/nodata.png');
    height: 73vh;
    width: 20vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.nodes-title-list {
    font-size: 26px;
    font-weight: 800;
    text-align: start;
    padding-left: 5%;
    padding-bottom: 2%;
    padding-top: 15px;
}

.help-icon {
    background-image: url('../assets/help.png');
    background-size: cover;
    height: 25px;
    width: 25px;
    margin-right: 8px;
}

.plus-icon {
    background-image: url('../assets/plus2.png');
    background-size: cover;
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.product-sample-title {
    font-size: 20px;
    text-align: start;
}

.product-sample-title-container {
    display: flex;
    width: 100%;
    padding-top: 15px;
}

.product-sample-container {
    padding-top: 18px;
}

.basic-product-sample-options {
    text-align: start;
    padding-top: 10px;
}

.basic-product-sample-options-divider {
    border-top: solid rgb(223, 221, 221) 1.5px;
    border-radius: 3px;
    display: grid;
    width: 90%;
}

.inside-basic-product-sample-options-divider {
    width: 300px;
    border-top: solid rgb(223, 221, 221) 1.5px;
    border-radius: 3px;
    display: grid;
}

.basic-product-sample-toggles {
    padding-top: 0px;
}

.keywords-sample-container {
    padding-top: 18px;
}

.left-sample-container {
    padding-left: 0px;
}

.right-sample-container {
    padding-right: 0px;
    padding-left: 0px;
}

.toogles-container {
    width: 100%;
}

.report-help-container {
    background-color: #f1890055;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 280px;
    border-radius: 10px;
    transition: 0.3s;
}

.report-help-container:hover {
    transition: 0.3s;
    -webkit-box-shadow: 6px 6px 15px -14px rgba(0, 0, 0, 0.85);
    -moz-box-shadow: 6px 6px 15px -14px rgba(0, 0, 0, 0.85);
    box-shadow: 6px 6px 15px -14px rgba(0, 0, 0, 0.85);
}

.report-help-label {
    color: #133641;
    margin-bottom: 0px;
    padding-right: 10px;
    font-weight: 600;
}

.report-parent-type-container {
    margin-top: 15px;
}

.report-type-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.dropdown {
    width: 100%;
}

.type-dropdown-button {
    color: #133641;
    border: solid #133641 1.5px;
    border-radius: 7px;
    background-color: white;
    height: 49px;
    width: 100%;
    text-align: start;
}

.type-dropdown-button-v2 {
    color: #133641;
    border: solid #133641 1.5px;
    border-radius: 7px;
    background-color: white;
    padding-inline: 23px;
    margin-bottom: 6px;
    text-align: start;
}

.v-calendar {
    width: 100% !important;
    margin: 0px;
}

.final-date-input-container {
    padding-right: 0px;
}

.disabled-dropdown {
    background-color: #f6f6f6;
    border: solid #687d8446 1.5px;
}

.sub-toggle-input {
    padding-left: 20px;
}

.sub-sub-toggle-input {
    padding-left: 40px;
}

.sub-text-input {
    width: 250px;
    height: 40px;
    margin-left: 20px;
}

.sub-sub-text-input {
    width: 250px;
    height: 40px;
    margin-left: 40px;
}

.sub-sub-sub-text-input {
    width: 250px;
    height: 40px;
    margin-left: 60px;
}

.margin-product-sample-families {
    margin-top: 0px;
}

.margin-families-form {
    margin-top: 8px;
}

.titulo-modal {
    color: #133641;
    font-weight: 700;
}

.modal-color-button {
    color: white;
    background-color: #f18a00;
    font-weight: 700;
}

.modal-font-button {
    color: #133641;
    font-weight: 700;
}

.settle-dates-input {
    margin-bottom: 6px;
}

.input-wrapper {
    position: relative;
    width: max-content;
}

.input-with-icon {
    padding-right: 30px;
    /* Ajusta este valor según el tamaño del icono */
}

.input-icon {
    position: absolute;
    top: 2px;
    right: -47px;
    font-size: 20px;
    /* Ajusta el tamaño del icono aquí */
    color: #ccc;
    background-color: transparent;
    border: none;
    /* Puedes ajustar el color del icono aquí */
}

.mailItemList {
    width: 350px;
    margin-left: 48px;
    margin-right: 10px;
    margin-top: 10px;
}

.delivery-freq-container {
    margin-bottom: 15px;
}

.sample-list {
    list-style: none;
}

.sample-help {
    margin-left: 10px;
}

.title-basic-product-sample-text {
    text-align: start;
    color: #133641;
    margin-left: 20px;
    margin-top: 8px;
    margin-bottom: 6px;
    font-size: 17px;
    left: 0%;
    top: 0%;
}

.basic-product-sample-text {
    text-align: start;
    color: #9a9999;
    margin-left: 20px;
    left: 0%;
    top: 0%;
}

.sub-basic-product-sample-text {
    padding-left: 20px;
}

.padding-20-left {
    margin-left: 20px !important;
}

.margin-10-bottom {
    margin-bottom: 20px;
}

.margin-8-top {
    margin-top: 9px;
}

.margin-3-top {
    margin-top: 3px;
}

.keywords-help-icon-container {
    display: flex;
    align-items: center;
}

#multiple {
    width: 100%;
    height: 130px;
    background-color: #f7f7f7;
    border-color: transparent;
}

#multiple option {
    text-align: center;
    padding: 3px;
}
</style>
  