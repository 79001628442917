<template>
  <div class="contenedor">
    <!-- Vista inicial, lista de informes (se oculta cuando se selecciona un informe)-->
    <section class="vista-lista-informes" v-show="!informeVisible">
      <div class="contenedor_titulo scroll_informes">
        <div class="titulo">
          <h2 class="titulo_texto">Informes:</h2>
          <div class="basic-product-sample-options-divider"></div>
        </div>
        <input class="form-control sub-text-input" type="text" placeholder="Buscar informe por nombre"
          v-model="searchReport" @input="filteredReports" />
        <h1 :hidden="this.loadedMessage == true">Cargando...</h1>
        <div class="vista_informes">
          <ul>
            <li v-for="reportId in filteredReportIds" :key="reportId">
              <div @click="mostrarInforme(reportId)" class="fila_informe">
                <a class="informe">{{ reportId }}</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- Vista visible una vez se ha seleccionado un informe-->
    <section class="vista-datos-informe" v-show="informeVisible">
      <div class="contenedor_titulo">
        <h1 class="titulo_texto titulo">Informe seleccionado: {{ selectedReportId }}</h1>
        <div class="basic-product-sample-options-divider"></div>
        <button class="boton_volver" @click="ocultarInforme"><img src="../assets/right-arrow.png" class="volver_flecha"
            width="30" /></button>
        <div class="formulario_container">
          <form>
            <div class="seccion_container">
              <h4 class="titulo_seccion">Informacion adicional:</h4>
              <div class="input">
                <h5>Bullets: </h5>
                <select class="type-dropdown-button" v-model="this.ask_for_bullets" id="ask_for_bullets">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <h5>Texto: </h5>
                <select class="type-dropdown-button" v-model="this.ask_for_text" id="ask_for_text">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <h5>Atributos: </h5>
                <select class="type-dropdown-button" v-model="this.ask_for_attributes" id="ask_for_attributes">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
            </div>
            <div class="seccion_container">
              <h4 class="titulo_seccion">Histórico (anual) de ventas y precios:</h4>
              <div class="input">
                <h5>Periodo de muestreo: </h5>
                <select class="type-dropdown-button" v-model="this.periodo_de_muestreo" id="periodo_de_muestreo">
                  <option value=1>puntual</option>
                  <option value=-1>recurrente</option>
                </select>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <div class="datepicker_container">
                  <h5>Primer día de muestra: </h5>
                  <Datepicker lang="es" placeholder="Primer día de muestra" position="right" v-model="sampleDateFirst"
                    format="DD-MM-YYYY" type="date" :required="false" showClearButton="true">
                  </Datepicker>
                </div>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <div class="datepicker_container">
                  <h5>Último día de muestra: </h5>
                  <Datepicker lang="es" placeholder="Último día de muestra" position="right" v-model="sampleDateLast"
                    format="DD-MM-YYYY" type="date" :required="false" showClearButton="true"
                    :disabled="periodo_de_muestreo == 1">
                  </Datepicker>
                </div>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <h5>Historico de ventas y precios: </h5>
                <select class="type-dropdown-button" v-model="this.ask_for_product_hist" id="ask_for_product_hist">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <div :hidden="this.periodo_de_muestreo == -1 || this.showDeliveryFrequency == false">
                  <div class="display_flex">
                    <div class="flex_column">
                      <div class="input_button_container">
                        <input class="form-control sub-text-input settle-dates-input" type="text"
                          placeholder="Ejemplo: 7" v-model="settleDatesInput"
                          :hidden="this.periodo_de_muestreo == -1" />
                        <div @click="addSettleDate()" class="plus-icon date-selector-margin"></div>
                        <div class="help-icon date-selector-margin" data-toggle="tooltip" data-placement="right"
                          title="¿Cuántos días quieres que pasen desde el primer día de muestreos hasta el día en el que quieres ver el informe terminado?">
                        </div>
                      </div>
                      <div class="item_container">
                        <ul v-show="this.settleDates.length">
                          <li class="mailItemList">
                            <SettleDatesList v-for="item in settleDates" :key="item" :categoryID="item"
                              :onClick="this.onDeleteSettleDate"></SettleDatesList>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="input" :hidden="this.periodo_de_muestreo == 1 || this.showDeliveryFrequency == false">
                <h5>Frecuencia: </h5>
                <select class="type-dropdown-button">
                  <option disabled value="">Frecuencia de entrega</option>
                  <option class="dropdown-item" value="weekly">Entrega Semanal</option>
                  <option class="dropdown-item" value="monthly">Entrega Mensual</option>
                  <option class="dropdown-item" value="quarterly">Entrega Trimestral</option>
                  <option class="dropdown-item" value="semesterly">Entrega Semestral</option>
                  <option class="dropdown-item" value="yearly">Entrega Anual</option>
                </select>
              </div>
            </div>

            <div class="seccion_container">
              <h4 class="titulo_seccion">Otro:</h4>
              <div class="input">
                <h5>Promos y cupones: </h5>
                <select class="type-dropdown-button" v-model="this.ask_for_coupon" id="ask_for_coupon">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <h5>Ofertas: </h5>
                <select class="type-dropdown-button" v-model="this.ask_for_offers" id="ask_for_offers">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <h5>Razones pérdida buybox: </h5>
                <select class="type-dropdown-button" v-model="this.ask_for_reasons" id="ask_for_reasons">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="input_button_container" :hidden="this.ask_for_reasons == false">
                <input class="form-control sub-text-input settle-dates-input" type="text"
                  placeholder="Nombre oficial del pujante" v-model="clientName"
                  :hidden="this.ask_for_reasons == false" />
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <h5>Valoraciones: </h5>
                <select class="type-dropdown-button" v-model="this.ask_for_rating" id="ask_for_rating">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <h5>Comentarios: </h5>
                <select class="type-dropdown-button" v-model="this.ask_for_comments" id="ask_for_comments">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="input" :hidden="this.ask_for_comments == false">
                <h5>Idioma: </h5>
                <select class="type-dropdown-button" v-model="this.mails_language" id="mails_language">
                  <option disabled value="">Idioma del mail</option>
                  <option value="ES">Español</option>
                  <option value="EN">Ingles</option>
                  <option value="DE">Aleman</option>
                  <option value="FR">Frances</option>
                  <option value="IT">Italiano</option>
                </select>
              </div>
              <div class="input_button_container" :hidden="this.ask_for_comments == false">
                <input class="form-control sub-text-input settle-dates-input" type="text"
                  placeholder="Nombre del destinatario" v-model="destinataryName" />
              </div>
              <div class="input-wrapper" :hidden="this.ask_for_comments == false">
                <input class="form-control width-mails" type="text" placeholder="Correos para notificar"
                  v-model="inputMail" />
                <div class="input-icon" style="cursor: pointer;">
                  <img src="../assets/plus3.png" width="45" height="45" @click="addMail()" />
                </div>
              </div>
              <div class="input" :hidden="this.ask_for_comments == false">
                <ul v-show="this.mailsToSend.length">
                  <li class="mailItemList">
                    <MailItemList v-for="item in mailsToSend" :key="item" :categoryID="item"
                      :onClick="this.onDeleteMail"></MailItemList>
                  </li>
                </ul>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <h5>Notificaciones por mail: </h5>
                <select class="type-dropdown-button" v-model="this.ask_for_mail_notifications"
                  id="ask_for_mail_notifications">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <h5>Precios: </h5>
                <select class="type-dropdown-button" v-model="this.ask_for_sales_and_price"
                  id="ask_for_sales_and_price">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="basic-product-sample-options-divider-short"></div>
              <div class="input">
                <h5>Prioridad: </h5>
                <select class="type-dropdown-button" v-model="this.reportPriority" id="reportPriority">
                  <option value=5>Baja</option>
                  <option value=10>Media</option>
                  <option value=15>Alta</option>
                  <option disabled value="">Otro</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class=btn_container>
          <input class="form-control token-input" type="text" placeholder="Token de usuario" v-model="userToken"
            required />
          <button class="edit-report-button" @click="modalShow">Editar informe</button>
        </div>
      </div>
    </section>
  </div>
  <!-- Confirmacion de edicion de informe-->
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="mi-modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title titulo-modal">¿Editar reporte?</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-default modal-font-button" @click="editarYProbarReporte"
            id="modal-btn-si">Si</button>
          <button type="button" class="btn modal-color-button" @click="modalClose" id="modal-btn-no">No</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Mensaje final, opcion de descargar JSON del informe editado -->
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="success-modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title titulo-modal">Reporte editado</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>El reporte se ha editado correctamnete y se encuentra almacenado en nuestro sistema, si necesitas
            información de cara al reporte contacta con el equipo de desarrollo.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" @click="downloadGenerateJSON"
            id="modal-btn-no">Descargar JSON editado</button>
          <button type="button" class="btn modal-color-button" @click="successModalClose"
            id="modal-btn-no">Perfecto</button>
        </div>
      </div>
    </div>
  </div>
  <ErrorToast :errorTitle="this.errorTitle" :errorDescription="this.errorDescription" />
</template>

<script>
import SampleToggle from '@/components/sampleToggle.vue';
import axios from 'axios';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import VueDatepickerUi from 'vue-datepicker-ui';
import SettleDatesList from '@/components/SettleDatesList.vue';
import { mapActions } from 'vuex';
import MailItemList from '@/components/MailItemList.vue';
import ErrorToast from '@/components/ErrorToast.vue';

export default {
  name: "EditReportView",
  components: {
    SettleDatesList,
    SampleToggle,
    ErrorToast,
    MailItemList,
    Datepicker: VueDatepickerUi,
  },
  data() {
    return {
      userToken: '',
      dataFromBackend: [],
      reportData: [],
      clientName: '',
      reportSelected: null,
      selectedReportId: null,
      informeVisible: false,
      ask_for_bullets: null,
      inputMailString: '',
      ask_for_text: null,
      ask_for_attributes: null,
      ask_for_product_hist: null,
      ask_for_coupon: null,
      ask_for_offers: null,
      ask_for_reasons: null,
      ask_for_rating: null,
      ask_for_comments: null,
      ask_for_mail_notifications: null,
      ask_for_sales_and_price: null,
      periodo_de_muestreo: null,
      sampleDateFirst: null,
      sampleDateLast: null,
      reportPriority: null,
      modalTitle: '',
      modalDescription: '',
      settleDates: [],
      mailsToSend: [],
      settleDatesInput: '',
      searchReport: '',
      loadedMessage: false,
      mails_language: null,
      destinataryName: null,
      showDeliveryFrequency: null,
      conf_json: {},
    };
  },
  computed: { //filtrar informes en la lista informes
    uniqueReportIds() {
      return [...new Set(this.dataFromBackend.map(item => item[1]))];
    },
    uniqueReportIds() {
      return [...new Set(this.dataFromBackend.map(item => item[1]))];
    },
    filteredReportIds() {
      const searchTerm = this.searchReport.toLowerCase();
      return this.uniqueReportIds.filter(reportId => reportId.toLowerCase().includes(searchTerm));
    }
  },
  methods: {
    ...mapActions(['editReport', 'getCSFRToken']),
    async editarYProbarReporte() {
      this.generarInformeEditado();
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });
      try {
        const resultado1 = await this.editReport({
          user_token: this.userToken,
          reporte: this.conf_json,
        })
        console.log(resultado1);
        loader.hide();
        this.pruebaTest(resultado1);
      } catch (error) {
        loader.hide();
        this.errorTitle = error;
        this.errorDescription = "Ha ocurrido un error a la hora de editar el reporte, consulta si el servicio está operativo y verifica la conexión a internet";
        $('.toast').toast({ delay: 7000 });
        $('.toast').toast('show');
        console.error(error);
      }
    },
    onDeleteMail(mail) {
      this.mailsToSend = this.mailsToSend.filter((x) => x !== mail);
    },
    addMail() {
      this.mailsToSend.push(this.inputMail);
      if (this.inputMail.trim() !== '') {
        if (this.inputMailString !== '') {
          this.inputMailString += ';';
        }
        this.inputMailString += this.inputMail.trim();
        this.inputMail = '';
      }
    },
    downloadGenerateJSON() {
      const contenidoJSONString = JSON.stringify(this.generatedJSON);
      const blob = new Blob([contenidoJSONString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'reporte_editado.json';
      a.click();
      URL.revokeObjectURL(url);
    },
    generarInformeEditado() { //se guardan los datos editados y no editados en un nuevo json
      this.conf_json['report_id'] = this.dataFromBackend[this.reportSelected][0];
      this.conf_json['report_name'] = this.dataFromBackend[this.reportSelected][1];
      this.conf_json['created'] = this.dataFromBackend[this.reportSelected][2];
      this.conf_json['prod_node_experiments'] = this.dataFromBackend[this.reportSelected][3];
      this.conf_json['prod_keyword_experiments'] = this.dataFromBackend[this.reportSelected][4];
      this.conf_json['prod_asins_experiments'] = this.dataFromBackend[this.reportSelected][5];
      this.conf_json['report_client'] = this.dataFromBackend[this.reportSelected][41];
      let familiesValues = []
      if (this.dataFromBackend[this.reportSelected][29] != null) {
        familiesValues.push(this.dataFromBackend[this.reportSelected][29]);
      }
      if (this.dataFromBackend[this.reportSelected][30] != null) {
        familiesValues.push(this.dataFromBackend[this.reportSelected][29]);
      }
      if (this.dataFromBackend[this.reportSelected][31] != null) {
        familiesValues.push(this.dataFromBackend[this.reportSelected][29]);
      }
      if (this.dataFromBackend[this.reportSelected][32] != null) {
        familiesValues.push(this.dataFromBackend[this.reportSelected][29]);
      }
      if (this.dataFromBackend[this.reportSelected][33] != null) {
        familiesValues.push(this.dataFromBackend[this.reportSelected][29]);
      }
      this.conf_json['families'] = familiesValues;
      this.conf_json['sampling_dates'] = this.dataFromBackend[this.reportSelected][41];
      this.conf_json['sampling_dates'] = {};
      this.conf_json['sampling_dates']['recurrent'] = this.dataFromBackend[this.reportSelected][27];
      this.conf_json['sampling_dates']['sample_date_first'] = this.dataFromBackend[this.reportSelected][26];
      this.conf_json['sampling_dates']['sample_date_last'] = this.dataFromBackend[this.reportSelected][28];
      this.conf_json['sampling_dates']['recurrent_download'] = this.dataFromBackend[this.reportSelected][38];
      this.conf_json['sampling_dates']['delivery_window'] = this.dataFromBackend[this.reportSelected][37];
      this.conf_json['sampling_dates']['report_priority'] = this.dataFromBackend[this.reportSelected][35];

      this.conf_json['node_sample_amount'] = this.dataFromBackend[this.reportSelected][34];
      this.conf_json['keyword_sample_amount'] = this.dataFromBackend[this.reportSelected][39];
      this.conf_json['endpoints'] = {};
      if (this.dataFromBackend[this.reportSelected][9]) {
        this.conf_json['endpoints']['SPH'] = {};
        this.conf_json['endpoints']['SPH']['settle_dates'] = this.dataFromBackend[this.reportSelected][36] != "" ? this.dataFromBackend[this.reportSelected][36].split(', ') : ["1"];
      }
      this.conf_json['endpoints']['P'] = {};
      this.conf_json['endpoints']['P']['extra_content'] = [];
      this.conf_json['endpoints']['P']['product_hist'] = false;
      this.conf_json['endpoints']['P']['max_days_permitted'] = Number(this.dataFromBackend[this.reportSelected][10]);
      this.conf_json['endpoints']['P']['coupons'] = false;
      if (this.dataFromBackend[this.reportSelected][11] == true) {
        this.conf_json['endpoints']['P']['coupons'] = this.dataFromBackend[this.reportSelected][11];
      }
      if (this.dataFromBackend[this.reportSelected][6] || this.dataFromBackend[this.reportSelected][7] || this.dataFromBackend[this.reportSelected][8]) {
        if (this.dataFromBackend[this.reportSelected][6]) this.conf_json['endpoints']['P']['extra_content'].push("bullets")
        if (this.dataFromBackend[this.reportSelected][7]) this.conf_json['endpoints']['P']['extra_content'].push("text")
        if (this.dataFromBackend[this.reportSelected][8]) this.conf_json['endpoints']['P']['extra_content'].push("attributes")
      }
      if (this.ask_for_reasons) {
        this.conf_json['endpoints']['O'] = {};
        this.conf_json['endpoints']['O']['client_name'] = this.dataFromBackend[this.reportSelected][35];
      }
      if (this.dataFromBackend[this.reportSelected][16]) {
        this.conf_json['endpoints']['R'] = {};
        this.conf_json['endpoints']['R']['content'] = ['stars'];
        if (this.dataFromBackend[this.reportSelected][16]) {
          this.conf_json['endpoints']['R']['content'].push('comments');
          this.conf_json['endpoints']['R']['notificacion_mails'] = this.mailsToSend;
          this.conf_json['endpoints']['R']['notification_client_name'] = this.destinataryName;
          this.conf_json['endpoints']['R']['notification_language'] = this.mails_language;
        }
      }
      this.conf_json['replace_existing'] = "reports";
      var json = JSON.stringify(this.conf_json);
      console.log(json);
    },
    async pruebaTest(response) {
      console.log(response)
      if (response['respuesta'].status < 300) {
        this.generatedJSON = response['info']
        this.successModalShow()
      } else {
        this.errorTitle = 'Error ' + response['respuesta'].status;
        this.errorDescription = response['respuesta'].statusText;
        $('.toast').toast({ delay: 7000 });
        $('.toast').toast('show');
      }
    },
    modalShow() {
      if (this.userToken != '')
        $("#mi-modal").modal('show');
    },
    modalClose() {
      $("#mi-modal").modal('hide');
    },
    successModalShow() {
      $("#success-modal").modal('show');
    },
    successModalClose() {
      $("#success-modal").modal('hide');
    },
    fetchDataFromBackend() { //se obtienen los datos de los informes existentes
      axios.get('https://baipmarket-prod.brainycommerce.com/api/reports/')
        .then(response => {
          this.dataFromBackend = response.data.data;
          this.loadedMessage = true;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    mostrarInforme(reportId) { //se muestra en el front los datos editables del informe con sus valores originales
      this.selectedReportId = reportId;
      this.reportSelected = this.uniqueReportIds.indexOf(reportId)
      this.informeVisible = true;
      this.reportData = this.dataFromBackend[this.reportSelected],
        this.ask_for_bullets = this.dataFromBackend[this.reportSelected][6],
        this.ask_for_text = this.dataFromBackend[this.reportSelected][7],
        this.ask_for_attributes = this.dataFromBackend[this.reportSelected][8],
        this.ask_for_product_hist = this.dataFromBackend[this.reportSelected][9],
        this.ask_for_coupon = this.dataFromBackend[this.reportSelected][11],
        this.ask_for_offers = this.dataFromBackend[this.reportSelected][12],
        this.ask_for_reasons = this.dataFromBackend[this.reportSelected][13],
        this.ask_for_rating = this.dataFromBackend[this.reportSelected][15],
        this.ask_for_comments = this.dataFromBackend[this.reportSelected][16],
        this.ask_for_mail_notifications = this.dataFromBackend[this.reportSelected][17],
        this.ask_for_sales_and_price = this.dataFromBackend[this.reportSelected][22],
        this.nr_settle_dates = this.dataFromBackend[this.reportSelected][23],
        this.sampleDateFirst = this.dataFromBackend[this.reportSelected][26],
        this.periodo_de_muestreo = this.dataFromBackend[this.reportSelected][27],
        this.sampleDateLast = this.dataFromBackend[this.reportSelected][28],
        this.settleDates[0] = this.dataFromBackend[this.reportSelected][36],
        this.reportPriority = this.dataFromBackend[this.reportSelected][35],
        this.destinataryName = this.dataFromBackend[this.reportSelected][21],
        this.mails_language = this.dataFromBackend[this.reportSelected][19],
        this.clientName = this.dataFromBackend[this.reportSelected][14]
      console.log(this.dataFromBackend[this.reportSelected]);
    },
    ocultarInforme() {
      this.selectedReportId = null;
      this.informeVisible = false;
    },
    addSettleDate() {
      this.settleDates.push(this.settleDatesInput);

      this.dataFromBackend[this.reportSelected][36] += "," + this.settleDatesInput

    },
    onDeleteSettleDate(settleDate) {
      this.settleDates = this.settleDates.filter((x) => x !== settleDate);
      var data = this.dataFromBackend[this.reportSelected][36]
      var dataArr = data.split(",")
      var newDataArr = dataArr.filter(item => item !== settleDate)
      var resultado = newDataArr.join()
      if (resultado == "") {
        this.dataFromBackend[this.reportSelected][36] = null
      }
      else {
        this.dataFromBackend[this.reportSelected][36] = resultado
      }
    },
    onDeleteSettleDate(settleDate) {
      this.settleDates = this.settleDates.filter((x) => x !== settleDate);
      var data = this.dataFromBackend[this.reportSelected][36]
      var dataArr = data.split(",")
      var newDataArr = dataArr.filter(item => item !== settleDate)
      var resultado = newDataArr.join()
      if (resultado == "") {
        this.dataFromBackend[this.reportSelected][36] = null
      }
      else {
        this.dataFromBackend[this.reportSelected][36] = resultado
      }
    },
  },
  watch: { //cada campo editable tiene un watch para guardar los cambios
    ask_for_bullets(newVal) {
      var newValBooleano = JSON.parse(newVal)
      this.dataFromBackend[this.reportSelected][6] = newValBooleano;
    },
    ask_for_text(newVal) {
      var newValBooleano = JSON.parse(newVal)
      this.dataFromBackend[this.reportSelected][7] = newValBooleano;
    },
    ask_for_attributes(newVal) {
      var newValBooleano = JSON.parse(newVal)
      this.dataFromBackend[this.reportSelected][8] = newValBooleano;
    },
    ask_for_product_hist(newVal) {
      var newValBooleano = JSON.parse(newVal)
      this.dataFromBackend[this.reportSelected][9] = newValBooleano;
      this.showDeliveryFrequency = newValBooleano
    },
    ask_for_coupon(newVal) {
      var newValBooleano = JSON.parse(newVal)
      this.dataFromBackend[this.reportSelected][11] = newValBooleano;
    },
    ask_for_offers(newVal) {
      var newValBooleano = JSON.parse(newVal)
      this.dataFromBackend[this.reportSelected][12] = newValBooleano;
    },
    ask_for_reasons(newVal) {
      var newValBooleano = JSON.parse(newVal)
      this.dataFromBackend[this.reportSelected][13] = newValBooleano;
      this.ask_for_reasons = newValBooleano;
      if (!newValBooleano) {
        this.clientName = "";
      }
    },
    ask_for_rating(newVal) {
      var newValBooleano = JSON.parse(newVal)
      this.dataFromBackend[this.reportSelected][15] = newValBooleano;
    },
    ask_for_comments(newVal) {
      var newValBooleano = JSON.parse(newVal)
      this.dataFromBackend[this.reportSelected][16] = newValBooleano;
      this.ask_for_comments = newValBooleano;
      if (!newValBooleano) {
        this.dataFromBackend[this.reportSelected][19] = "";
        this.mails_language = "";
        this.dataFromBackend[this.reportSelected][21] = "";
        this.destinataryName = "";
      }
    },
    mails_language(newVal) {
      this.dataFromBackend[this.reportSelected][19] = newVal;
    },
    ask_for_mail_notifications(newVal) {
      var newValBooleano = JSON.parse(newVal)
      this.dataFromBackend[this.reportSelected][17] = newValBooleano;
    },
    ask_for_sales_and_price(newVal) {
      var newValBooleano = JSON.parse(newVal)
      this.dataFromBackend[this.reportSelected][22] = newValBooleano;
    },
    settleDates(newVal) {
      this.dataFromBackend[this.reportSelected][36] + "," + newVal;
    },
    sampleDateFirst(newVal) {

      const parsedDate = new Date(newVal);

      const day = parsedDate.getDate().toString().padStart(2, '0');
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
      const year = parsedDate.getFullYear();

      const formattedDate = `${year}-${month}-${day}`;

      this.dataFromBackend[this.reportSelected][26] = formattedDate;
    },
    sampleDateLast(newVal) {
      const parsedDate = new Date(newVal);

      const day = parsedDate.getDate().toString().padStart(2, '0');
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
      const year = parsedDate.getFullYear();

      const formattedDate = `${year}-${month}-${day}`;

      this.dataFromBackend[this.reportSelected][28] = formattedDate;

    },

    periodo_de_muestreo(newVal) {
      if (newVal == 1) {
        this.dataFromBackend[this.reportSelected][27] = 1;
      } else {
        this.dataFromBackend[this.reportSelected][27] = -1;
        this.dataFromBackend[this.reportSelected][28] = null;
      }
    },
    clientName(newVal) {
      this.dataFromBackend[this.reportSelected][14] = newVal;
    },
    destinataryName(newVal) {
      this.dataFromBackend[this.reportSelected][21] = newVal;
    },
    reportPriority(newVal) {
      if (newVal == 5) {
        this.dataFromBackend[this.reportSelected][35] = 5;
      }
      else if (newVal == 10) {
        this.dataFromBackend[this.reportSelected][35] = 10;
      }
      else if (newVal == 15) {
        this.dataFromBackend[this.reportSelected][35] = 15;
      }
      else {
        this.dataFromBackend[this.reportSelected][35] = "";
        this.reportPriority = "";
      }
    }
  },
  mounted() {
    this.fetchDataFromBackend();
  }
};
</script>

<style scoped>
.plus-icon {
  background-image: url('../assets/plus2.png');
  background-size: cover;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.sub-text-input {
  width: 250px;
  height: 40px;
  margin-top: 20px;
}

.datepicker_container {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-bottom: 10px;
}

.type-dropdown-button {
  color: #133641;
  border: solid #133641 1.5px;
  border-radius: 7px;
  background-color: white;
  text-align: start;
}

.volver_flecha {
  transform: rotate(180deg);
}

.input-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.boton_volver {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  background-color: #FAD8AA;
  border: 0;
  height: 35px;
  width: 100px;
  margin-top: 20px;
  border-radius: 10px;
  transition: all 0.5 ease-in;
}

.boton_volver:hover {
  background-color: #E4BE86;
}

.item_container {
  max-height: 150px;
  overflow-y: auto;
}

.formulario_container {
  height: 65vh;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.fila_informe {
  background-color: #F8F8F8;
  border-radius: 5px;
  padding: 10px;
  margin: 20px;
  margin-left: 0px;
  cursor: pointer;
}

.contenedor_titulo {
  width: 90%;
}

.vista_informes {
  margin-top: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
}

.contenedor {
  height: 100vh;
}

.titulo {
  margin-top: 60px
}

.titulo_texto {
  text-align: start;
  font-size: 30px;
  font-weight: 800;
}

.seccion_container {
  background-color: #F8F8F8;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
}

.width-mails {
  width: 250px;
}

.display_flex {
  display: flex;
}

ul {
  list-style-type: none;
}

li {
  text-align: start;
}

.vista-lista-informes {
  display: flex;
  justify-content: center;
}

.vista-datos-informe {
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
  color: inherit;
}

.help-icon {
  background-image: url('../assets/help.png');
  background-size: cover;
  height: 25px;
  width: 25px;
}

.date-selector-margin {
  margin-left: 2%;
  width: 100%;
}

.form-control {
  color: #133641;
  border-color: #133641;
  border-radius: 8px;
  padding: 23px;
}

.settle-dates-input {
  margin-bottom: 20px;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.titulo_seccion {
  text-align: start;
  width: 30%;
}

.input {
  width: 35%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  align-items: start;
}

.edit-report-button {
  border: 0;
  background-color: #f18a00;
  font-weight: 700;
  font-size: 18px;
  color: white;
  padding: 10px 25px;
  width: fit-content;
  height: 50px;
  margin-left: 15px;
  border-radius: 5px;

}

.token-input {
  width: 400px;
}

.input_button_container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.edit-report-button:hover {
  transition: 0.3s;
  -webkit-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
}

.basic-product-sample-options-divider {
  border-top: solid rgb(223, 221, 221) 1.5px;
  border-radius: 3px;
  display: grid;
  width: 98%;
}

.basic-product-sample-options-divider-short {
  border-top: solid rgb(223, 221, 221) 1.5px;
  border-radius: 3px;
  display: grid;
  width: 35%;
}

.mailItemList {
  width: 300px;
  margin-top: 5px;
}


.btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.titulo-modal {
  color: #133641;
  font-weight: 700;
}

.modal-color-button {
  color: white;
  background-color: #f18a00;
  font-weight: 700;
}

.modal-font-button {
  color: #133641;
  font-weight: 700;
}
</style>