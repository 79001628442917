<template>
  <div class="toggle-endpoint">
    <label class="switch">
      <input type="checkbox" @click="this.toggleCheckbox(toggleName)" />
      <div class="slider round"></div>
    </label>
    <p class="endpoint-text" :class="{ active: toggleActive }">
      {{ toggleText }}
    </p>
  </div>
</template>

<script>
import { Switch } from '@headlessui/vue';
export default {
  props: {
    toggleActive: Boolean,
    toggleText: String,
    toggleName: String,
    toggleCheckbox: undefined,
  },
  components: {
    Switch,
  },
};
</script>

<style scoped>
.toggle-endpoint {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
  margin: 0;
}

.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #133641;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3.2px;
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateX(29px);
  -ms-transform: translateX(29px);
  transform: translateX(29px);
}

input:checked + .slider {
  background-color: #133641;
}

input:focus + .slider {
  box-shadow: 0 0 1px #133641;
}

input:checked + .slider:before {
  -webkit-transform: translateX(29px);
  -ms-transform: translateX(29px);
  transform: translateX(29px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.endpoint-text {
  color: #949596;
  margin-left: 10px;
  transition: 0.3s;
  white-space: nowrap;
}
</style>
