<template>
    <div class="csv-download-button-container">
        <div @click="downloadCSV" class="csv-download-button row " style="cursor: pointer;">
            <img src="../assets/download.png" class="csv-download-button-icon"/>
            <p>Descargar plantilla</p>
        </div>
    </div>
</template>
  
<script>
export default {
    props:{
        downloadType: ''
    },
    methods: {
        downloadCSV() {
            console.log(this.downloadType)
            let csvContent = ''
            let csvName = ''
            if (this.downloadType == 'node'){
                csvContent = "IDNodo;Marketplace;Familia1;Familia2;Familia3;Familia4;Familia5;Nickname\n4965422031;ES;Cuerda;Madera;Grande;;;Guitarras clasicas\n4965469031;ES;Cuerda;Madera;Pequeño;;;Ukelele\n4965478031;ES;Vientos;Metal;Pequeño;;;Armonicas";
                csvName = "plantilla_nodos.csv";
            } else if (this.downloadType == 'keyword'){
                csvContent = "Keyword;Marketplace;Familia1;Familia2;Familia3;Familia4;Familia5;\nChampu para el cabello;ES;Liquido;Pelo;;;\nGel de cuerpo;ES;Liquido;Cuerpo;;;";
                csvName = "plantilla_keywords.csv"
            }else{
                csvContent = "ASIN;Marketplace;Familia1;Familia2;Familia3;Familia4;Familia5;\nBA3241242;ES;O34542;XXL;pantalones;lana;\nB019IZEMQ0;FR;X43689;S;coquilla;poliester;;";
                csvName = "plantilla_asins.csv"
            }
            const blob = new Blob([csvContent], { type: "text/csv;charset=ISO-8859-1;" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", csvName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },
};
</script>

<style>
.csv-download-button-container{
    height: 49px;
    margin-top: 8px;
    margin-right: 30px;
}

.csv-download-button{
    background-color: white;
    border: 0px;
    color: #133641;
    border: solid #133641 1.5px;
    border-radius: 7px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
}

.csv-download-button p {
    font-weight: 500;
} 

.csv-download-button-icon{
    height: 23px;
    padding-right: 10px;
}
</style>