<template>
    <div class="main">
        <div>
            <h1 class="col-12">Proximamente...</h1>
        </div>
        <div class="desc">
            <p>En esta página se podrán ver y administrar los reportes generados desde la plataforma</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.main {
    background-color: white;
    display: grid;
    justify-content: center;
}

.main h1 {
    font-size: 100px;
    color: #c6c6c6;
    margin-top: 10%;
    font-weight: 800;
    font-family: 'Nunito', sans-serif;
}
.desc{
    border-radius: 10px;
    position: absolute;
    top: 40%;
    width: 40%;
    padding: 3%;
    left: 37%;
    -webkit-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
}
</style>