<template>
  <div class="main">
    <div class="navbar-menu">
      <div class="logo"></div>
      <div class="navbar-menu-content">
        <div class="navbar-items">
          <router-link to="/" class="navbar-item-link">
            <div class="navbar-item">
              <p>Alta de informes</p>
            </div>
          </router-link>
          <router-link to="/editarInformes" class="navbar-item-link">
            <div class="navbar-item">
              <p>Editar informes</p>
            </div>
          </router-link>
          <router-link to="/editarExperimentos" class="navbar-item-link">
            <div class="navbar-item">
              <p>Editar experimentos</p>
            </div>
          </router-link>
          <router-link to="/crearExperimentos" class="navbar-item-link">
            <div class="navbar-item">
              <p>Crear experimentos</p>
            </div>
          </router-link>
        </div>
        <p class="version-text">v3.0 🚗</p>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<style>
@font-face {
  font-family: Recoleta;
  src: local('Recoleta'),
    url('~@/assets/fonts/Recoleta-Bold.otf') format('OpenType');
}

* {
  font-family: 'Nunito', Helvetica, Arial !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: white;
  height: 100vh;
}
* {
  font-family: 'Nunito', sans-serif !important;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
p {
  margin: 0;
  padding: 0;
  font-weight: 800;
}
a:hover {
  text-decoration: none;
}

.main {
  display: flex;
  height: 100vh;
  background-color: white;
}

.navbar-menu {
  background-color: #ededed;
  width: 25%;
  height: 100vh;
  max-width: 300px;
}
.navbar-menu-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 86%;
}
.logo {
  height: 10%;
  background-image: url('@/assets/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 8%;
}

.navbar-items {
  position: relative;
  margin-top: 15%;
}

.navbar-item {
  background-color: #ffffff;
  color: #133641;
  border-radius: 7px;
  margin: 5% 5%;
  padding: 5% 0;
}

.navbar-item:hover {
  transition: 0.3s;
  border: none;
  -webkit-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
}

.navbar-item-link {
  text-decoration: none;
  color: white;
  font-weight: 600;
}

.content {
  width: 100%;
  background-color: white;
}

.router-link-exact-active .navbar-item {
  background-color: #133641; /*#6 64 78 */
  /*#b63030*/
  color: white;
}

.router-link-exact-active .menu-elto-icon {
  color: white;
}

.logout-button {
  width: 100%;
  margin-bottom: 4%;
}
.logout-button button {
  width: 90%;
  border: solid #133641 2px;
  color: #133641;
  font-weight: 500;
  border-radius: 7px;
  padding: 4%;
}
.logout-button button p {
  font-weight: 800;
  color: #133641;
}
.logout-button button:disabled {
  opacity: 1;
  cursor: default;
}
.version-text{
  font-size: 120%;
  padding-bottom: 15px;
}
</style>
