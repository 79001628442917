import { createStore } from 'vuex';
import axios from 'axios';
const cors = require('cors');
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    editReport({ commit }, params) {
      return new Promise((resolve, reject) => {
        var conf_json = params.reporte;
        $("#mi-modal").modal('hide');
        //const url = 'https://baipmarket-dev.brainycommerce.com/api/reports/';
        const url = 'https://baipmarket-prod.brainycommerce.com/api/reports/';
        //const url = 'http://127.0.0.1:8000/api/report/';
        console.log("x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x")
        var csrftoken = undefined
        const value = `; ${document.cookie}`;
        console.log(value)
        const parts = value.split(`; csrftoken=`);
        if (parts.length === 2) csrftoken = parts.pop().split(';').shift();
        console.log(csrftoken)
        console.log("x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x")
        axios.defaults.withCredentials = true;
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + params.user_token,
            'X-CSRFToken': csrftoken 
          },
          credentials: 'include',
          body: JSON.stringify({'report': conf_json })
        };
        fetch(url, requestOptions)
          .then(response => {
            resolve({'respuesta':response, 'info':{'report': conf_json }});
          })
          .catch(error => {
            reject(error)
        });
      })
    },
    generateReport({ commit }, params) {
      return new Promise((resolve, reject) => {
        var conf_json = {};
        var nodesObject = [];
        var keywordsObject = [];
        var asinsObject = []
        var productSampleDef = [];
        var keywordSampleDef = [];
        let familiesValues = [params.family1, params.family2, params.family3, params.family4, params.family5]
        //Creación del objeto de nodos
        params.nodes.forEach((x, indice) => {
          let obj = { marketplace: '', category_id: '', Family1: '', Family2: '', Family3: '', Family4: '', Family5: '', nickname: '' };
          let data = x.split('-');
          obj.marketplace = data[0];
          obj.category_id = data[1];
          obj.nickname = data[7];
          familiesValues.forEach((x, indice) => {
            obj['Family' + (indice + 1).toString()] = data[indice + 2]
          })
          for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === '') {
              delete obj[key];
            }
          }
          nodesObject.splice(indice, 0, obj);
        })
        //Creación del objeto de keywords
        params.keywords.forEach((x, indice) => {
          let obj = { marketplace: '', keyword: '', Family1: '', Family2: '', Family3: '', Family4: '', Family5: '' };
          let data = x.split('-');
          obj.marketplace = data[0];
          obj.keyword = data[1];
          familiesValues.forEach((x, indice) => {
            obj['Family' + (indice + 1).toString()] = data[indice + 2]
          })
          for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === '') {
              delete obj[key];
            }
          }
          keywordsObject.splice(indice, 0, obj);
          let refinements = [];
          let refinement_names = [];
          if (data[7] != '') { refinements.push(data[7]); refinement_names.push(data[11]); }
          if (data[8] != '') { refinements.push(data[8]); refinement_names.push(data[12]); }
          if (data[9] != '' && data[10] != '') { refinements.push("p_36/" + data[9] * 100 + "-" + data[10] * 100); refinement_names.push(data[9] + "-" + data[10]); }
          if (data[7] != '' || data[8] != '' || data[9] != '' || data[10] != '') {
            obj.refinements = refinements.join(',');
            obj.refinement_names = refinement_names.join(',');
          }
        })
        //Creación del objeto de asins
        params.asins.forEach((x, indice) => {
          let obj = { marketplace: '', ASIN: '', Family1: '', Family2: '', Family3: '', Family4: '', Family5: '' };
          let data = x.split('-');
          obj.marketplace = data[0];
          obj.ASIN = data[1];
          familiesValues.forEach((x, indice) => {
            obj['Family' + (indice + 1).toString()] = data[indice + 2]
          })
          for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === '') {
              delete obj[key];
            }
          }
          asinsObject.splice(indice, 0, obj);
        })
        conf_json['report_name'] = params.name;
        conf_json['report_id'] = params.name.split(' ').join('_');
        conf_json['created'] = new Date().toLocaleDateString('sv-SE');
        conf_json['report_client'] = params.report_client;
        conf_json['families'] = []
        for (let i = 0; i < familiesValues.length; i++) {
          if (familiesValues[i] != '') {
            conf_json['families'].push(familiesValues[i])
          }
        }
        conf_json['node_list'] = nodesObject;                             
        conf_json['keyword_list'] = keywordsObject;                       
        conf_json['asin_list'] = asinsObject;                             
        conf_json['sampling_dates'] = {};                                  
        conf_json['sampling_dates']['recurrent'] = params.reportType == 'puntual' ? -1 : 1;
        console.log(params.sample_date_first)
        conf_json['sampling_dates']['sample_date_first'] = params.sample_date_first != '' ? params.sample_date_first.toLocaleDateString('sv-SE') : params.sample_date_first;
        conf_json['sampling_dates']['sample_date_last'] = params.sample_date_last != '' ? params.sample_date_last.toLocaleDateString('sv-SE') : params.sample_date_last;
        console.log(params.recurrent_download)
        conf_json['sampling_dates']['recurrent_download'] = params.recurrent_download;
        conf_json['sampling_dates']['delivery_window'] = parseInt(params.delivery_window);
        conf_json['sampling_dates']['report_priority'] = parseInt(params.report_priority);
        conf_json['node_sample_amount'] = parseInt(params.nodes_sample_amount);
        conf_json['keyword_sample_amount'] = parseInt(params.keywords_sample_amount) * 50;
        if (params.node_endpoint) productSampleDef.push('N');
        if (params.keyword_endpoint) productSampleDef.push('K');
        if (params.asin_endpoint) productSampleDef.push('A');
        conf_json['product_sample_def'] = productSampleDef;
        if (params.keyword_search_endpoint) keywordSampleDef.push('K');
        conf_json['keyword_sample_def'] = [];
        conf_json['endpoints'] = {};
        if (params.sales_price_endpoint) {
          conf_json['endpoints']['SPH'] = {};
          conf_json['endpoints']['SPH']['settle_dates'] = params.settle_dates != "" ? params.settle_dates.split(', ') : ["1"];
        }
        if (params.product_endpoint) {
          conf_json['endpoints']['P'] = {};
          conf_json['endpoints']['P']['extra_content'] = [];
          if (params.product_bullets) conf_json['endpoints']['P']['extra_content'].push("bullets")
          if (params.product_texto) conf_json['endpoints']['P']['extra_content'].push("text")
          if (params.product_atributos) conf_json['endpoints']['P']['extra_content'].push("attributes")
          conf_json['endpoints']['P']['product_hist'] = false;
          conf_json['endpoints']['P']['max_days_permitted'] = Number(params.product_max_days);
          conf_json['endpoints']['P']['coupons'] = params.coupons_endpoint;
        }
        if (params.buybox_endpoint) {
          conf_json['endpoints']['O'] = {};
          conf_json['endpoints']['O']['client_name'] = params.buybox_client_name;
        }
        if (params.reviews_endpoint) {
          conf_json['endpoints']['R'] = {};
          conf_json['endpoints']['R']['content'] = ['stars'];
          if (params.reviews_comments) conf_json['endpoints']['R']['content'].push('comments')
          if (params.reviews_mails) {
            conf_json['endpoints']['R']['notificacion_mails'] = params.mails_str;
            conf_json['endpoints']['R']['notification_client_name'] = params.reviews_destinatary;
            conf_json['endpoints']['R']['notification_language'] = params.mails_language;
          }
        }
        console.log(conf_json);
        var json = JSON.stringify(conf_json);
        console.log(json);
        $("#mi-modal").modal('hide');
        //const url = 'https://baipmarket-dev.brainycommerce.com/api/report/';
        const url = 'https://baipmarket-prod.brainycommerce.com/api/report/';
        //const url = 'http://127.0.0.1:8000/api/report/';
        console.log("x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x")
        var csrftoken = undefined
        const value = `; ${document.cookie}`;
        console.log(value)
        const parts = value.split(`; csrftoken=`);
        if (parts.length === 2) csrftoken = parts.pop().split(';').shift();
        console.log(csrftoken)
        console.log("x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x")
        axios.defaults.withCredentials = true;
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + params.user_token,
            'X-CSRFToken': csrftoken // Agrega el token al encabezado
          },
          credentials: 'include',
          body: JSON.stringify({ 'report': conf_json })
        };
        fetch(url, requestOptions)
          .then(response => {
            resolve({ 'respuesta': response, 'info': { 'report': conf_json } });
          })
          .catch(error => {
            reject(error)
          });
      })
    },
    getCSFRToken() {
      //axios.get('https://baipmarket-dev.brainycommerce.com/api/login/')
      axios.get('https://baipmarket-prod.brainycommerce.com/api/login/') // Cambia la URL a la que corresponda
      //axios.get('http://127.0.0.1:8000/api/login/')

        .then(response => {
          console.log(response)
          const csrfToken = response.data.csrf_token;
          document.cookie = `csrftoken=${csrfToken}; domain=127.0.0.1; path=/`;
          console.log('CSRF token almacenado en la cookie.');
        })
        .catch(error => {
          console.error('Error al obtener el CSRF token:', error);
        });
    }
  },
  modules: {},
});
