<template>
    <div class="item-list-container">
      <div class="row justify-content-between item-list-background-container">
        <div class="col-10">
          <p class="categoryID-text">{{ categoryID }}</p>
        </div>
        <div class="col-2 delete-item-cross">
          <button class="delete-item-button">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtklEQVR4nGNgGAUjE2jbBhzStgs4o2cZKEZILUiNtp3/WW27gINEW6Bj639Kxy7gv7at/2V8loANt/W/DFKrY+d/kmgLDGxCRbVtAy5BLbmuYRsoSUiNln2oBNEWELLEgFLD8VliQC3DsYWztq3/ZWQ2MYmAZJ/oUMvlhCzQwBLxgy+IDLBEKDFJmGzDGXDIkWwJMUnRgBJLtG0DTpNcVNj6nyLBAv/jpBV2AWdAeoi2YBQMLwAAPVOaDTPYqJkAAAAASUVORK5CYII="
              @click="onClick(this.categoryID)"
            />
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      categoryID: String,
      onClick: undefined,
    },
  };
  </script>
  
  <style scoped>
  .item-list-container {
    width: 85%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
  
  .item-list-background-container {
    background-color: #e9e8e8;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .categoryID-text {
    text-align: start;
  }
  
  .delete-item-cross {
    text-align: end;
  }
  
  .delete-item-button{
    border: 0px;
    background: transparent;
  }
  </style>