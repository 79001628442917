<template>
  <div class="row col-12 insert-inputs-container">
    <div class="col-12 col-md-9 input-group">
      <div class="input-group mycustom">
        <input
          type="text"
          class="form-control"
          v-bind:id="id"
          v-bind:placeholder="placeholder"
          aria-describedby="inputGroupPrepend2"
          v-on:focusin="onHover"
          v-on:focusout="onHover"
        />
        <div class="input-group-prepend" v-show="hidden">
          <input
            type="submit"
            value="Insertar"
            class="btn btn-sm input-group-prepend-text"
            id="inputGroupPrepend2"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3 load-csv-container">
      <label
        class="form-control load-csv-container-input"
        for="file"
        type="text"
        >Cargar csv</label
      >
      <input
        id="file"
        type="file"
        style="display: none"
        accept=".csv"
        @change="handleFileUpload($event)"
      />
    </div>
  </div>
</template>

<script>
import Papa from 'papaparse';
import axios from 'axios';

export default {
  props: {
    onChangeCSV: () => {},
    placeholder: String,
    id: String,
  },
  data() {
    return {
      hidden: false,
      file: '',
      content: [],
      parsed: false,
    };
  },
  methods: {
    onHover: function (event) {
      this.hidden = !this.hidden;
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      this.parseFile();
    },
    parseFile() {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          this.content = results;
          this.parsed = true;
        }.bind(this),
      });
    },
  },
};
</script>

<style>
.form-control {
  color: #133641;
  border: solid #133641 1.5px;
  border-radius: 7px;
}

.mycustom input[type='text'] {
  height: 46px;
  border-radius: 7px !important;
}

.mycustom .input-group-prepend {
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 4px;
  z-index: 9;
}

.input-group {
  padding-left: 0%;
}

.input-group-prepend-text {
  color: #f18a00;
  font-weight: 800;
  font-size: 90%;
}

.insert-inputs-container {
  padding-top: 10px;
}

.insert-inputs-container {
  padding-right: 0%;
}

.load-csv-container {
  padding-right: 0%;
  display: inline-block;
  vertical-align: middle;
}

.load-csv-container-input {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.customFile {
  background-color: transparent;
}
</style>
