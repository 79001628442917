import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TrackersView from '../views/TrackersView.vue'
import EditarInformesView from '../views/EditarInformesView.vue'
import EditarExperimentosView from '../views/EditarExperimentosView.vue'
import CrearExperimentos from '../views/CrearExperimentos.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/trackers',
    name: 'trackers',
    component: TrackersView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/editarInformes',
    name: 'editarInformes',
    component: EditarInformesView
  },
  {
    path: '/editarExperimentos',
    name: 'editarExperimentos',
    component: EditarExperimentosView
  },
  {
    path: '/crearExperimentos',
    name:'crearExperimentos',
    component: CrearExperimentos
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
