<template>
  <header class="cabecera">
    <p class="inputs-title-less">Editar experimentos:</p>
    <div class="basic-product-sample-options-divider"></div>
  </header>
  <section class="main-section">
    <ErrorToast :errorTitle="this.errorTitle" :errorDescription="this.errorDescription" />
    <section class="submain-section">
      <section class="select-section">
        <select class="btn type-dropdown-button" v-model="selectedReportId" @change="filterNicknames">
          <option disabled value="">Selecciona un informe</option>
          <option v-for="reportId in uniqueReportIds" :key="reportId" :value="reportId">
            {{ reportId }}
          </option>
        </select>
        <div class="date-selector-row">
          <Datepicker lang="es" class="date-input" placeholder="Selecciona una fecha" position="right"
            v-model="dateToChange" format="DD-MM-YYYY" type="date" :required="true">
          </Datepicker>
        </div>
        <div class="filter-btns">
          <button class="filter-btn" @click="filterByNull12">Tracking stopped (NO)</button>
          <button class="filter-btn" @click="filterByNotNull12">Tracking stopped (YES)</button>
        </div>
        <div class="create-report">
          <input class="filter-btn" type="text" placeholder="Token de usuario" v-model="userToken" required />
          <button class="btn create-report-button" @click="modalShow()">
            Modificar experimento
          </button>
        </div>
        <!--<button @click="testNicknames()">CLICK ME</button> -->
      </section>
      <section class="check-section">
        <div class="column">
          <div>
            <ul>
              <li class="list" v-for="(nickname, index) in firstColumn" :key="index">
                {{ nickname }}
                <input class="checkbox" type="checkbox" :value="nickname" v-model="selectedNicknames[nickname]">
              </li>
            </ul>
          </div>

          <div>
            <ul>
              <li class="list" v-for="(nickname, index) in secondColumn" :key="index">
                {{ nickname }}
                <input class="checkbox" type="checkbox" :value="nickname" v-model="selectedNicknames[nickname]">
              </li>
            </ul>
          </div>
        </div>

        <div class="btn-page">
          <button class="page-buttons" @click="changePage(page - 1)" :disabled="page === 1">Anterior</button>
          <button class="page-buttons" @click="changePage(page + 1)" :disabled="page === totalPages">Siguiente</button>
        </div>
      </section>
    </section>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="mi-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title titulo-modal">¿Generar reporte?</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-default modal-font-button" @click="createExperiment()"
              id="modal-btn-si">Si</button>
            <button type="button" class="btn modal-color-button" @click="modalClose" id="modal-btn-no">No</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="success-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title titulo-modal">Reporte subido</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>El reporte se ha generado correctamnete y se encuentra almacenado en nuestro sistema, si necesitas
              información de cara al reporte contacta con el equipo de desarrollo.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" @click="downloadGenerateJSON"
              id="modal-btn-no">Descargar JSON generado</button>
            <button type="button" class="btn modal-color-button" @click="successModalClose"
              id="modal-btn-no">Perfecto</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import VueDatepickerUi from 'vue-datepicker-ui';
import ErrorToast from '@/components/ErrorToast.vue';

export default {
  name: 'EditarExperimentosView',
  components: {
    Datepicker: VueDatepickerUi,
    ErrorToast
  },
  data() {
    return {
      dataFromBackend: [],
      selectedReportId: '',
      page: 1,
      pageSize: 50,
      selectedNicknames: {},
      filteredData: [],
      dateToChange: '',
      originalData: [], // Variable de respaldo para los datos originales
      reportName: '',
      nodesListData: [],
      keywordsListData: [],
      asinsListData: [],
      tracking_stopped: '',
      tracking_started: '',
      csrfToken: '',
      userToken: '',
      errorTitle: '',
      errorDescription: ''
    };
  },
  computed: {
    uniqueReportIds() {
      return [...new Set(this.dataFromBackend.map(item => item[1]))];
    },
    filteredNicknames() {
      this.page = 1;
      return this.filteredData
        .filter(item => item[1] === this.selectedReportId)
        .map(item => {
          if (item[2].includes('K')) {
            return [item[4], item[17], item[5]];
          } else {
            return [item[4], item[5]];
          }
        });
    },
    paginatedNicknames() {
      const startIndex = (this.page - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.filteredNicknames.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredNicknames.length / this.pageSize);
    },
    firstColumn() {
      return this.paginatedNicknames.slice(0, 20);
    },
    secondColumn() {
      return this.paginatedNicknames.slice(20, 40);
    }
  },
  methods: {
    fetchDataFromBackend() {
      axios.get('https://baipmarket-prod.brainycommerce.com/api/experiments/')
        .then(response => {
          this.dataFromBackend = response.data.data;
          this.originalData = [...response.data.data]; // Guardar los datos originales
          this.filterNicknames();
          console.log(this.dataFromBackend);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    filterNicknames() {
      this.selectedNicknames = {};
      // Restaurar los datos originales al cambiar de reporte
      this.filteredData = [...this.originalData];
    },
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.page = page;
    },
    testNicknames() {
      console.log(this.selectedNicknames);
      console.log(this.selectedReportId);
      console.log(this.dateToChange);
    },
    filterByNull12() {
      this.filteredData = this.originalData.filter(nickname => nickname[12] === null);
    },
    filterByNotNull12() {
      this.filteredData = this.originalData.filter(nickname => nickname[12] !== null);
    },
    createExperiment() {
      const selectedNicknamesArray = Object.keys(this.selectedNicknames).filter(key => this.selectedNicknames[key]);
      const parsedDate = this.dateToChange;

      const day = parsedDate.getDate().toString().padStart(2, '0');
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
      const year = parsedDate.getFullYear();

      const formattedDate = `${year}-${month}-${day}`;
      const report = {
        report_id: this.selectedReportId,
        node_list: [],
        keyword_list: [],
        asin_list: [],
        replace_existing: "experiments"
      };

      selectedNicknamesArray.forEach(nicknameWithMarketplace => {
        const [nickname, marketplace] = nicknameWithMarketplace.split(',');
        const index = this.filteredData.findIndex(item => item[4] === nickname && item[5] === marketplace);

        if (index !== -1 && this.filteredData[index]) {
          const [_, __, type, asin, nickname, _____, family1, family2, family3, family4, family5, tracking_started, tracking_stopped] = this.filteredData[index];

          if (type === 'N') {
            const itemN = {
              marketplace: marketplace,
              nickname: nickname,
              category_id: asin,
              Family1: family1,
              Family2: family2,
              Family3: family3,
              Family4: family4,
              Family5: family5,
              tracking_started: tracking_started,
              tracking_stopped: tracking_stopped
            };
            if (tracking_stopped === null) {
              itemN.tracking_stopped = formattedDate;

            }
            else {
              itemN.tracking_stopped = null;
              itemN.tracking_started = formattedDate;
            }
            report.node_list.push(itemN);
          } else if (type === 'A') {
            const itemA = {
              marketplace: marketplace,
              ASIN: asin,
              Family1: family1,
              Family2: family2,
              Family3: family3,
              Family4: family4,
              Family5: family5,
              tracking_started: tracking_started,
              tracking_stopped: tracking_stopped
            };
            if (tracking_stopped === null) {
              itemA.tracking_stopped = formattedDate;

            }
            else {
              itemA.tracking_stopped = null;
              itemA.tracking_started = formattedDate;
            }
            report.asin_list.push(itemA);
          } else if (type === 'K') {
            const itemK = {
              marketplace: marketplace,
              keyword: asin,
              Family1: family1,
              Family2: family2,
              Family3: family3,
              Family4: family4,
              Family5: family5,
              tracking_started: tracking_started,
              tracking_stopped: tracking_stopped
            };
            if (tracking_stopped === null) {
              itemK.tracking_stopped = formattedDate;

            }
            else {
              itemK.tracking_stopped = null;
              itemK.tracking_started = formattedDate;
            }
            report.keyword_list.push(itemK);
          }
        } else {
          console.error("No se pudo encontrar el índice para el nickname:", nickname);
        }
      });



      const jsonString = JSON.stringify(report, null, 2);
      console.log(jsonString);

      var conf_json = report;
      var json = JSON.stringify(conf_json);
      console.log(json);
      $("#mi-modal").modal('hide');
      //const url = 'https://baipmarket-dev.brainycommerce.com/api/reports/';
      const url = 'https://baipmarket-prod.brainycommerce.com/api/reports/';
      //const url = 'http://127.0.0.1:8000/api/report/';
      console.log("x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x")
      var csrftoken = undefined
      const value = `; ${document.cookie}`;
      console.log(value)
      const parts = value.split(`; csrftoken=`);
      if (parts.length === 2) csrftoken = parts.pop().split(';').shift();
      console.log(csrftoken)
      console.log("x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x")
      axios.defaults.withCredentials = true;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + this.userToken,
          'X-CSRFToken': csrftoken // Agrega el token al encabezado
        },
        credentials: 'include',
        body: JSON.stringify({ 'report': conf_json })
      };
      return fetch(url, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Error en la modificacion del reporte');
          }
          return response.json();
        })
        .then(data => {
          // Manejar el JSON devuelto, si es necesario
          console.log(data);
          this.successModalShow(); // Mostrar el modal de éxito
        })
        .catch(error => {
          this.errorTitle = error.message;
          this.errorDescription = "Consulte la consola para mas informacion sobre este error";
          $('.toast').toast({ delay: 7000 });
          $('.toast').toast('show');
          console.error(error);
        });


      this.successModalShow();
    },
    modalShow() {
      if (this.userToken != '')
        $("#mi-modal").modal('show');
    },
    modalClose() {
      $("#mi-modal").modal('hide');
    },
    successModalShow() {
      $("#success-modal").modal('show');
    },
    successModalClose() {
      $("#success-modal").modal('hide');
    }







  },
  mounted() {
    this.fetchDataFromBackend();
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
}

li {
  text-align: start;
}

.inputs-title-less {
  text-align: start;
  font-size: 35px;
  font-weight: 800;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-left: 5%;
}

.basic-product-sample-options-divider {
  border-top: solid rgb(223, 221, 221) 1.5px;
  border-radius: 3px;
  display: grid;
  width: 90%;
  margin-left: 5%;
}

.filter-btn {
  background-color: #ffffff;
  color: #133641;
  border-radius: 7px;
  margin: 5% 0%;
  padding: 5% 2%;
}

.modal-color-button {
  color: white;
  background-color: #f18a00;
  font-weight: 700;
}

.modal-font-button {
  color: #133641;
  font-weight: 700;
}

.create-report {
  width: 100%;
  margin-top: 50%;
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: stretch;
}

.help-icon {
  background-image: url('../assets/help.png');
  background-size: cover;
  height: 25px;
  width: 25px;
  margin-right: 8px;
}

.form-control {
  color: #133641;
  border-color: #133641;
  border-radius: 8px;
  padding: 23px;
}

.token-div {
  display: flex;
}

.create-report-button {
  background-color: #f18a00;
  font-weight: 700;
  font-size: 18px;
  color: white;
  padding: 10px 25px;
  width: fit-content;
  height: 50px;
}

.create-report-button:hover {
  transition: 0.3s;
  -webkit-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
}

.filter-btns {
  display: flex;
  flex-direction: column;
}

.select-section {
  width: 30%;
  height: 100%;
  padding-left: 5%;
  padding-top: 5%;
}

.cabecera {
  height: 5%;
}

.date-selector-row {
  margin-top: 10%;
  margin-bottom: 5%;
}

.date-selector {
  display: flex;
  float: left;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.check-section {
  width: 70%;
  height: 100%;
  position: relative;
}

.btn-page {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  font-size: large;
}

.checkbox {
  margin-left: 20px;
  transform: scale(1.5);
}

.page-buttons {
  background-color: #f18a00;
  font-weight: 700;
  font-size: 10px;
  color: white;
  padding: 5px 12px;
  height: 30px;
  margin-left: 10px;
}

.submain-section {
  display: flex;
  width: 100%;
  height: 97%;
}

.main-section {
  height: 95%;
  padding-bottom: 2%;
}

.column {
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.type-dropdown-button {
  color: #133641;
  border: solid #133641 1.5px;
  border-radius: 7px;
  background-color: white;
  height: 49px;
  width: 100%;
  text-align: start;
}

.input-field {
  margin-top: 30px;
}
</style>
