<template>
  <div class="home">
    <ErrorToast :errorTitle="this.errorTitle" :errorDescription="this.errorDescription" />
    <div class="basic-inputs vl-parent">
      <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
      <div class="row masterrow">
        <div class="col-12 col-md-7 inputs-container">
          <div class="d-flex col-12 justify-content-between marketplace-input">
            <div class="title">
              <p>{{ title }}👋</p>
            </div>
            <button class="report-help-container" :onclick="abrirDocumentacionNuevaPestana">
              <p class="report-help-label" type="text">¿Cómo hacer un informe?</p>
              <img src="../assets/right-arrow.png" class="report-help-image" width="30" />
            </button>
          </div>
          <p class="inputs-title-less">Información general</p>
          <div class="basic-product-sample-options-divider"></div>
          <form @submit.prevent="getFormValues" class="report-form-container">
            <input type="hidden" name="csrfmiddlewaretoken" :value="csrfToken" />
            <div class="row">
              <div class="col-12 col-md-8 name-input">
                <input class="form-control" type="text" placeholder="Nombre del informe" v-model="reportName"
                  required />
              </div>
              <div class="col-12 col-md-4 report-type-container">
                <div class="dropdown">
                  <select class="btn type-dropdown-button" v-model="reportType" @change="onChange" required>
                    <option disabled value="">Elija el periodo de muestreo</option>
                    <option class="dropdown-item" value="puntual">Un día</option>
                    <option class="dropdown-item" value="recurrente">Varios días</option>
                  </select>
                </div>
              </div>
              <div class="date-selector row">
                <div class="col-12 col-md-6">
                  <Datepicker lang="es" class="date-input" placeholder="Primer día de muestra" position="right"
                    v-model="sampleDateFirst" format="DD-MM-YYYY" type="date" :required="true">
                  </Datepicker>
                </div>
                <div class="col-12 col-md-6 final-date-input-container">
                  <div class="dropdown">
                    <select class="btn type-dropdown-button" v-model="reportPriority" @change="onChange" required>
                      <option disabled value="">Prioridad del informe</option>
                      <option class="dropdown-item" value="5">Baja</option>
                      <option class="dropdown-item" value="10">Media</option>
                      <option class="dropdown-item" value="15">Alta</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-md-6 date-selector-row">
                  <Datepicker lang="es" class="date-input" placeholder="Último día de muestra" position="right"
                    v-model="sampleDateLast" :disabled="reportType != 'recurrente'" format="DD-MM-YYYY" type="date"
                    showClearButton="true">
                  </Datepicker>
                </div>
                <div class="col-12 col-md-6 final-date-input-container date-selector-row">
                  <div class="dropdown">
                    <select class="btn type-dropdown-button" v-model="reportClient" @change="onChange" required>
                      <option disabled value="">Cliente del reporte</option>
                      <option v-for="item in clients" :key="item" class="dropdown-item" :value="item[0]">{{ item[0] }}
                      </option>
                      <option class="dropdown-item" value="Prospect">Prospect</option>
                      <option class="dropdown-item" value="Otros">Otros</option>
                    </select>
                  </div>
                </div>
              </div>
              <p class="inputs-title row col-12">Definición de la muestra</p>
              <div class="basic-product-sample-options-divider"></div>
              <div class="col-12 marketplace-input">
                <div class="product-sample-title-container margin-product-sample-families">
                  <p class="product-sample-title">¿Por qué agrupaciones quieres clasificar la muestra?</p>
                  <div class="help-icon date-selector-margin" data-toggle="tooltip" data-placement="right"
                    title="Puedes agrupar los elementos de la muestra por hasta 5 agrupaciones. Supongamos un informe en el que se estudian los pasillos de ukeleles, guitarras y armónicas. Una agrupación posible sería 'materiales' (madera o aluminio). Otra posible agrupación sería 'tamaño' (grande o pequeño). Pon aquí el título de estas agrupaciones. En el ejemplo sería 'materiales' y 'tamaño'.">
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-4 families-container">
                    <input class="form-control margin-families-form" type="text" placeholder="Agrupación 1"
                      v-model="family1Name" />
                  </div>
                  <div class="col-12 col-md-4 families-container">
                    <input class="form-control margin-families-form" type="text" placeholder="Agrupación 2"
                      v-model="family2Name" />
                  </div>
                  <div class="col-12 col-md-4 marketplace-input families-container">
                    <input class="form-control margin-families-form" type="text" placeholder="Agrupación 3"
                      v-model="family3Name" />
                  </div>
                  <div class="col-12 col-md-4 families-container">
                    <input class="form-control margin-families-form" type="text" placeholder="Agrupación 4"
                      v-model="family4Name" />
                  </div>
                  <!--
                  <div class="col-12 col-md-4 families-container">
                    <input
                        class="form-control margin-families-form"
                        type="text"
                        placeholder="Agrupación 5"
                        v-model="family5Name"
                    />
                  </div>
                  -->
                </div>
              </div>
              <div class="product-sample-title-container">
                <p class="product-sample-title">¿Cuál es el origen de la muestra?</p>
                <div class="help-icon date-selector-margin" data-toggle="tooltip" data-placement="right"
                  title="Los ASINs que compongan la muestra para el estudio pueden provenir de una lista de pasillos (tomaremos los ASINs del BSR), de una lista de keywords (tomaremos los ASINs de la página de resultados) y/o de una lista plana de ASINs que introduzcas directamente.">
                </div>
              </div>
              <div class="basic-product-sample-toggles">
                <SampleToggle :toggleActive="checkboxNodes" toggleText="Pasillo" toggleName="checkboxNodes"
                  :toggleCheckbox="this.toggleCheckbox" />
                <input class="form-control sub-text-input margin-8-top" type="text"
                  placeholder="ASINs por BSR de pasillo" v-model="nodesSampleAmount" :hidden="!checkboxNodes" />
                <div class="row align-items-center" :hidden="!checkboxNodes">
                  <p class="title-basic-product-sample-text">¿Qué pasillos quieres analizar?</p>
                </div>
                <SampleDefinitionComponent class="padding-20-left" :hidden="!checkboxNodes" :marketplaces="marketplaces"
                  :saveData="this.insertNode" :processFile="this.nodeParseFile" placeholder="Insertar ID del pasillo" 
                  reportType="node" :refinements="false"/>
                <div class="basic-product-sample-options-divider padding-20-left margin-10-bottom"
                  :hidden="!checkboxNodes"></div>
                <SampleToggle :toggleActive="checkboxKeywords" toggleText="Keyword" toggleName="checkboxKeywords"
                  :toggleCheckbox="this.toggleCheckbox" />
                <div :hidden="!checkboxKeywords">
                  <div class="keywords-help-icon-container margin-8-top">
                    <input class="form-control sub-text-input" type="text" placeholder="Páginas por keyword"
                      v-model="keywordsSampleAmount" />
                    <div class="help-icon date-selector-margin" data-toggle="tooltip" data-placement="right"
                      title="Ten en cuenta que cada página de keywords incluye 50 ASINs (o menos si no hay tantos resultados)">
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <p class="title-basic-product-sample-text">¿Qué keywords quieres analizar?</p>
                  </div>
                  <SampleDefinitionComponent class="padding-20-left" :marketplaces="marketplaces"
                    :saveData="this.insertKeyword" :processFile="this.keywordsParseFile" placeholder="Insertar keyword"
                    reportType="keyword" :refinements="true"/>
                  <div class="basic-product-sample-options-divider padding-20-left margin-10-bottom"></div>
                </div>
                <SampleToggle :toggleActive="checkboxAsins" toggleText="Lista de ASINs" toggleName="checkboxAsins"
                  :toggleCheckbox="this.toggleCheckbox" />
                <div class="row align-items-center" :hidden="!checkboxAsins">
                  <p class="title-basic-product-sample-text">¿Qué ASINs quieres analizar?</p>
                </div>
                <SampleDefinitionComponent class="padding-20-left" :hidden="!checkboxAsins" :marketplaces="marketplaces"
                  :saveData="this.insertAsin" :processFile="this.asinsParseFile" placeholder="Insertar ASIN"
                  reportType="asin" :refinements="false"/>
                <div class="basic-product-sample-options-divider padding-20-left margin-10-bottom"
                  :hidden="!checkboxAsins"></div>
              </div>
              <div class="insert-input-container col-12">
                <!--
                <div class="product-sample-title-container">
                  <p class="product-sample-title">¿Qué elementos (pasillos, keywords y/o ASINs) quieres incorporar?</p>
                </div>
                <div class="row align-items-center report-parent-type-container">
                  <div class="col-12 col-md-4 report-type-container">
                    <div class="dropdown">
                      <select class="btn type-dropdown-button" v-model="reportModule" @change="onModuleChange">
                        <option class="dropdown-item" value="nodes" selected>Pasillos</option>
                        <option class="dropdown-item" value="keywords" >Keywords</option>
                        <option class="dropdown-item" value="asins" >ASINs</option>
                      </select>
                    </div>
                  </div>
                  <div
                    class="help-icon sample-help"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Recuerda que en caso de categorías es obligatorio añadir un apodo (Nombre que define la categoría) de cada una de ellas"
                  ></div>
                </div>
                <SampleDefinitionComponent  :hidden="reportModule!='keywords'" :marketplaces="marketplaces" :saveData="this.insertKeyword" :processFile="this.keywordsParseFile" placeholder="Insertar keyword" reportType="keyword"/>
                <SampleDefinitionComponent  :hidden="reportModule!='asins'" :marketplaces="marketplaces" :saveData="this.insertAsin" :processFile="this.asinsParseFile" placeholder="Insertar ASIN" reportType="asin"/>
                -->
              </div>
              <div class="row toogles-container">
                <div class="col-lg-12 col-md-12 left-sample-container">
                  <div class="product-sample-container">
                    <p class="inputs-title-no-padding row col-12">Información a solicitar de cada ASIN de la muestra</p>
                    <div class="basic-product-sample-options-divider"></div>
                    <div class="basic-product-sample-toggles">
                      <SampleToggle toggleText="¿Quieres información adicional de textos de la página de detalle?"
                        toggleName="checkboxExtraProduct" :toggleActive="checkboxExtraProduct"
                        :toggleCheckbox="this.toggleCheckbox" />
                      <SampleToggle class="sub-toggle-input" :toggleActive="checkboxBullets" toggleText="Bullets"
                        toggleName="checkboxBullets" :toggleCheckbox="this.toggleCheckbox"
                        :hidden="!checkboxExtraProduct" />
                      <SampleToggle class="sub-toggle-input" :toggleActive="checkboxTexto" toggleText="Texto"
                        toggleName="checkboxTexto" :toggleCheckbox="this.toggleCheckbox"
                        :hidden="!checkboxExtraProduct" />
                      <SampleToggle class="sub-toggle-input" :toggleActive="checkboxAtributos" toggleText="Atributos"
                        toggleName="checkboxAtributos" :toggleCheckbox="this.toggleCheckbox"
                        :hidden="!checkboxExtraProduct" />
                      <SampleToggle :toggleActive="checkboxSalesPriceHistory"
                        toggleText="¿Quieres el histórico (anual) de ventas y precios?"
                        toggleName="checkboxSalesPriceHistory" :toggleCheckbox="this.toggleCheckbox" />
                      <p class="basic-product-sample-text"
                        :hidden="!checkboxSalesPriceHistory || reportType != 'recurrente'">¿Cada cuanto quieres que
                        actualicemos los datos para su presentación?</p>
                      <div class="col-12 col-md-9 final-date-input-container delivery-freq-container sub-text-input"
                        :hidden="!checkboxSalesPriceHistory || reportType != 'recurrente'">
                        <div class="dropdown">
                          <select class="btn type-dropdown-button-v2"
                            :class="{ 'disabled-dropdown': reportType != 'recurrente' }" v-model="recurrentDownload"
                            @change="onChange" :disabled="reportType != 'recurrente'">
                            <option disabled value="">Frecuencia de entrega</option>
                            <!-- <option class="dropdown-item" value="weekly">Semanal</option> -->
                            <option class="dropdown-item" value="weekly">Entrega Semanal</option>
                            <option class="dropdown-item" value="monthly">Entrega Mensual</option>
                            <option class="dropdown-item" value="quarterly">Entrega Trimestral</option>
                            <option class="dropdown-item" value="semesterly">Entrega Semestral</option>
                            <option class="dropdown-item" value="yearly">Entrega Anual</option>
                            <!-- <option class="dropdown-item" value="annually" >Anual</option> -->
                          </select>
                        </div>
                      </div>
                      <p class="basic-product-sample-text margin-8-top"
                        :hidden="!checkboxSalesPriceHistory || reportType == 'recurrente'">¿Cuántos días quieres que
                        pasen
                        desde el primer día de muestreos hasta el día en el que quieres ver el informe terminado??</p>
                      <div class="keywords-help-icon-container"
                        :hidden="!checkboxSalesPriceHistory || reportType == 'recurrente'">
                        <input class="form-control sub-text-input settle-dates-input" type="text"
                          placeholder="Ejemplo: 7" v-model="settleDatesInput"
                          :hidden="!checkboxSalesPriceHistory || reportType == 'recurrente'" />
                        <div @click="addSettleDate()" class="plus-icon date-selector-margin">
                        </div>
                        <div class="help-icon date-selector-margin" data-toggle="tooltip" data-placement="right"
                          :hidden="!checkboxSalesPriceHistory || reportType == 'recurrente'"
                          title="Cuantos más días dejemos pasar, mejor será la estimación de los últimos días de la muestra (algo mejores).">
                        </div>
                      </div>
                      <ul v-show="this.settleDates.length">
                        <li class="mailItemList">
                          <SettleDatesList v-for="item in settleDates" :key="item" :categoryID="item"
                            :onClick="this.onDeleteSettleDate"></SettleDatesList>
                        </li>
                      </ul>
                      <div class="row align-items-center" :hidden="!checkboxSalesPriceHistory">
                        <p class="basic-product-sample-text margin-3-top">¿Cuantos días pasan desde el último día de la
                          muestra hasta el día en que quieres ver el informe terminado?</p>
                      </div>
                      <div class="keywords-help-icon-container" :hidden="!checkboxSalesPriceHistory">
                        <input class="form-control sub-text-input" type="text" placeholder="Ejemplo: 10"
                          v-model="deliveryWindow" :hidden="!checkboxSalesPriceHistory" />
                        <div class="help-icon date-selector-margin" data-toggle="tooltip" data-placement="right"
                          :hidden="!checkboxSalesPriceHistory"
                          title="Este dato es importante porque es el número de días que tenemos para descargarnos las estimaciones de precio y ventas. Una ventana demasiado pequeña podría llevarnos a no poder entregar a tiempo la información. Para más información consulta con el equipo de desarrollo">
                        </div>
                      </div>
                      <SampleToggle :toggleActive="checkboxBuybox"
                        toggleText="¿Quieres el listado de pujantes de la buybox?" toggleName="checkboxBuybox"
                        :toggleCheckbox="this.toggleCheckbox" />
                      <SampleToggle class="sub-toggle-input" :toggleActive="checkboxReasonBuybox"
                        toggleText="¿Quieres conocer las razones de pérdida de la buybox del cliente?"
                        toggleName="checkboxReasonBuybox" :toggleCheckbox="this.toggleCheckbox"
                        :hidden="!checkboxBuybox" />
                      <div class="row align-items-center" :hidden="!checkboxBuybox || !checkboxReasonBuybox">
                        <input class="form-control sub-sub-text-input" type="text"
                          placeholder="Nombre oficial del pujante" v-model="clientName" />
                        <div class="help-icon sample-help" data-toggle="tooltip" data-placement="right"
                          title="Es importante que pongas exactamente el nombre público del cliente (El que aparece en la página de detalle de Amazon). Solo así podremos reconocer la puja.">
                        </div>
                      </div>
                      <SampleToggle :toggleActive="checkboxReviews"
                        toggleText="¿Quieres obtener información de las valoraciones y/o comentarios?"
                        toggleName="checkboxReviews" :toggleCheckbox="this.toggleCheckbox" />
                      <SampleToggle class="sub-toggle-input" :toggleActive="checkboxSummary" toggleText="Valoraciones"
                        toggleName="checkboxSummary" :toggleCheckbox="this.toggleCheckbox" :hidden="!checkboxReviews" />
                      <SampleToggle class="sub-toggle-input" :toggleActive="checkboxComments" toggleText="Comentarios"
                        toggleName="checkboxComments" :toggleCheckbox="this.toggleCheckbox"
                        :hidden="!checkboxReviews" />
                      <SampleToggle class="sub-sub-toggle-input" :toggleActive="checkboxNotify"
                        toggleText="Notificar Comentarios" toggleName="checkboxNotify"
                        :toggleCheckbox="this.toggleCheckbox" :hidden="!checkboxComments" />
                      <div class="row align-items-center settle-dates-input"
                        :hidden="!checkboxNotify || !checkboxComments">
                        <select class="type-dropdown-button-v2 sub-sub-sub-text-input" v-model="mailLanguage"
                          @change="onChange">
                          <option disabled value="">Idioma del mail</option>
                          <option class="dropdown-item" value="ES">Español</option>
                          <option class="dropdown-item" value="EN">Ingles</option>
                          <option class="dropdown-item" value="DE">Aleman</option>
                          <option class="dropdown-item" value="FR">Frances</option>
                          <option class="dropdown-item" value="IT">Italiano</option>
                        </select>
                        <input class="form-control sub-sub-sub-text-input" type="text"
                          placeholder="Nombre del destinatario" v-model="destinataryMailName" />
                        <div class="help-icon sample-help" data-toggle="tooltip" data-placement="right"
                          title="Este nombre lo utilizaremos para referirnos al desnitinatario en la plantilla del correo. Para incluir el logo del cliente en el correo, ponte en contacto con el equipo de desarrollo.">
                        </div>
                      </div>
                      <div class="input-wrapper" :hidden="!checkboxNotify || !checkboxComments">
                        <input class="form-control sub-sub-sub-text-input input-with-icon" type="text"
                          placeholder="Correos para notificar" v-model="inputMail" />
                        <div class="input-icon" style="cursor: pointer;">
                          <img src="../assets/plus3.png" width="45" height="45" @click="addMail()" />
                        </div>
                      </div>
                      <ul v-show="this.mailsToSend.length">
                        <li class="mailItemList">
                          <MailItemList v-for="item in mailsToSend" :key="item" :categoryID="item"
                            :onClick="this.onDeleteMail"></MailItemList>
                        </li>
                      </ul>
                      <SampleToggle :toggleActive="checkboxCoupons"
                        toggleText="¿Quieres obtener información de promos y cupones?" toggleName="checkboxCoupons"
                        :toggleCheckbox="this.toggleCheckbox" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="create-report">
                <div class="help-icon" data-toggle="tooltip" data-placement="right"
                  title="Token necesario de autentificación, en caso de no tener un token activo, comuníquese con el equipo de desarrollo">
                </div>
                <div class="col-12 col-md-6 name-input">
                  <input class="form-control" type="text" placeholder="Token de usuario" v-model="userToken" required />
                </div>
                <button class="btn create-report-button" @click="modalShow()">
                  Crear informe
                </button>
              </div>
            </div>
          </form>
          <p class="create-report-help">
            ¿Necesitas ayuda? Escríbenos a developers@brainycommerce.com 😄
          </p>
        </div>
        <div class="inputs-list-master-main-container">

          <div v-if="!this.nodesListData.length && !this.keywordsListData.length && !this.asinsListData.length"
            class="no-data-div"></div>
          <ul v-show="this.nodesListData.length || this.keywordsListData.length || this.asinsListData.length"
            class="sample-list">
            <p v-show="this.nodesListData.length" class="nodes-title-list">
              Nodos
            </p>
            <li v-show="this.nodesListData.length">
              <ItemList v-for="item in nodesListData" :key="item" :categoryID="item"
                :families="[this.family1Name, this.family2Name, this.family3Name, this.family4Name, this.family5Name]"
                :onClick="this.onDeleteNode"></ItemList>
            </li>
            <p v-show="this.keywordsListData.length" class="nodes-title-list">
              Keywords
            </p>
            <li v-show="this.keywordsListData.length">
              <ItemList v-for="item in keywordsListData" :key="item" :categoryID="item"
                :families="[this.family1Name, this.family2Name, this.family3Name, this.family4Name, this.family5Name, ]"
                :onClick="this.onDeleteKeyword"></ItemList>
            </li>
            <p v-show="this.asinsListData.length" class="nodes-title-list">
              ASIN's
            </p>
            <li v-show="this.asinsListData.length">
              <ItemList v-for="item in asinsListData" :key="item" :categoryID="item"
                :families="[this.family1Name, this.family2Name, this.family3Name, this.family4Name, this.family5Name]"
                :onClick="this.onDeleteAsin"></ItemList>
            </li>
          </ul>

        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="mi-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title titulo-modal">¿Generar reporte?</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-default modal-font-button" @click="generarYProbarReporte()"
              id="modal-btn-si">Si</button>
            <button type="button" class="btn modal-color-button" @click="modalClose" id="modal-btn-no">No</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="success-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title titulo-modal">Reporte subido</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>El reporte se ha generado correctamnete y se encuentra almacenado en nuestro sistema, si necesitas
              información de cara al reporte contacta con el equipo de desarrollo.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" @click="downloadGenerateJSON"
              id="modal-btn-no">Descargar JSON generado</button>
            <button type="button" class="btn modal-color-button" @click="successModalClose"
              id="modal-btn-no">Perfecto</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportBasicInput from '@/components/ReportBasicInput.vue';
import ReportInsertInput from '@/components/ReportInsertInput.vue';
import DownloadNodeTemplate from '@/components/DownloadNodeTemplate.vue';
import SampleDefinitionComponent from '@/components/SampleDefinitionComponent'
import ItemList from '@/components/ItemList.vue';
import MailItemList from '@/components/MailItemList.vue';
import SettleDatesList from '@/components/SettleDatesList.vue';
import ErrorToast from '@/components/ErrorToast.vue';
import SampleToggle from '@/components/sampleToggle.vue';
import SampleToggleActive from '@/components/sampleToggleActive.vue';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import VueDatepickerUi from 'vue-datepicker-ui';
import { Switch } from '@headlessui/vue';
import { mapActions } from 'vuex';
import axios from 'axios';
import Papa from 'papaparse';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
export default {
  name: 'HomeView',
  components: {
    ReportBasicInput,
    ReportInsertInput,
    ItemList,
    MailItemList,
    SettleDatesList,
    ErrorToast,
    Datepicker: VueDatepickerUi,
    Switch,
    SampleToggle,
    SampleToggleActive,
    DownloadNodeTemplate,
    SampleDefinitionComponent
  },
  created() {
    const dateHour = new Date().getHours();
    if (6 <= dateHour && dateHour < 14) {
      this.title = 'Buenos días ';
    } else if (14 <= dateHour && dateHour < 21) {
      this.title = 'Buenas tardes ';
    } else {
      this.title = 'Buenas noches ';
    }
  },
  mounted() {
    this.getReportClients()
  },
  data() {
    return {
      stopLoading: false,
      title: 0,
      csrfToken: '',
      userToken: '',
      reportName: '',
      reportType: '',
      filterMarketplace: '',
      reportPriority: '',
      sampleDateFirst: '',
      sampleDateLast: '',
      reportClient: '',
      clients: [],
      refinements: [],
      deliveryDate: null,
      recurrentDownload: '',
      deliveryWindow: '',
      refinementsBrands: [],
      refinementsDepartments: [],
      refinementsPriceMin: '',
      refinementsPriceMax: '',
      settleDatesInput: '',
      settleDatesString: '',
      reportModule: 'nodes',
      content: [],
      parsed: false,
      nodesListData: [],
      keywordsListData: [],
      asinsListData: [],
      family1Name: '',
      family2Name: '',
      family3Name: '',
      family4Name: '',
      family5Name: '',
      checkboxNodes: false,
      nodesSampleAmount: '',
      checkboxKeywords: false,
      checkboxNoFilters: false,
      checkboxFilters: false,
      keywordsSampleAmount: '',
      keywordFilters: '',
      checkboxAsins: false,
      checkboxSalesPriceHistory: false,
      checkboxSettleDates: false,
      checkboxProduct: true,
      checkboxExtraProduct: false,
      maxDaysPermitted: '365',
      refinementsData: '',
      checkboxBullets: false,
      checkboxTexto: false,
      checkboxAtributos: false,
      checkboxBuybox: false,
      checkboxReasonBuybox: false,
      clientName: '',
      checkboxReviews: false,
      checkboxSummary: false,
      checkboxComments: false,
      checkboxNotify: false,
      inputMail: '',
      mailsToSend: [],
      settleDates: [],
      inputMailString: '',
      destinataryMailName: '',
      mailLanguage: '',
      checkboxCoupons: false,
      checkboxKeywordSearchHistory: false,
      hiddeNode: false,
      hiddeKeywords: false,
      modalTitle: '',
      modalDescription: '',
      errorTitle: '',
      errorDescription: '',
      generatedJSON: '',
      marketplaces: [
        'AE', 'AU', 'BE', 'BR', 'CA', 'DE', 'EG', 'ES', 'FR', 'IN', 'IT', 'JP', 'COM.MX', 'NL', 'PL', 'SA', 'SE', 'SG', 'TR', 'UK', 'COM',
      ],
    };
  },
  methods: {
    ...mapActions(['generateReport', 'getCSFRToken']),
    async generarYProbarReporte() {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });
      try {
        const resultado1 = await this.generateReport({
          user_token: this.userToken,
          name: this.reportName,
          report_priority: this.reportPriority,
          sample_date_first: this.sampleDateFirst,
          sample_date_last: this.sampleDateLast,
          reportType: this.reportType,
          report_client: this.reportClient,
          delivery_date: this.deliveryDate,
          recurrent_download: this.recurrentDownload,
          delivery_window: this.deliveryWindow,
          nodes: this.nodesListData,
          keywords: this.keywordsListData,
          asins: this.asinsListData,
          family1: this.family1Name,
          family2: this.family2Name,
          family3: this.family3Name,
          family4: this.family4Name,
          family5: this.family5Name,
          node_endpoint: this.checkboxNodes,
          nodes_sample_amount: this.nodesSampleAmount,
          keyword_endpoint: this.checkboxKeywords,
          noFilters_endpoint: this.checkboxNoFilters,
          Filters_endpoint: this.checkboxFilters,
          keywords_sample_amount: this.keywordsSampleAmount,
          keywordFilters_endpoint: this.keywordFilters,
          filterMarketplace_endpoint: this.filterMarketplace,
          asin_endpoint: this.checkboxAsins,
          sales_price_endpoint: this.checkboxSalesPriceHistory,
          sales_price_settle_dates: this.checkboxSettleDates,
          settle_dates: this.settleDatesString,
          product_endpoint: this.checkboxProduct,
          product_max_days: this.maxDaysPermitted,
          product_bullets: this.checkboxBullets,
          product_texto: this.checkboxTexto,
          product_atributos: this.checkboxAtributos,
          buybox_endpoint: this.checkboxBuybox,
          buybox_client_name: this.clientName,
          reviews_endpoint: this.checkboxReviews,
          reviews_summary: this.checkboxSummary,
          reviews_comments: this.checkboxComments,
          reviews_mails: this.checkboxNotify,
          mails_str: this.inputMailString,
          mails_language: this.mailLanguage,
          reviews_destinatary: this.destinataryMailName,
          coupons_endpoint: this.checkboxCoupons,
          keyword_search_endpoint: this.checkboxKeywordSearchHistory,
        })
        // Llama a pruebaTest con resultado1 después de que generateReport termine
        console.log(resultado1);
        loader.hide();
        this.pruebaTest(resultado1);
      } catch (error) {
        loader.hide();
        this.errorTitle = error;
        this.errorDescription = "Ha ocurrido un error a la hora de generar el reporte, consulta si el servicio está operativo y verifica la conexión a internet";
        $('.toast').toast({ delay: 7000 });
        $('.toast').toast('show');
        console.error(error);
      }
    },
    pruebaTest(response) {
      console.log("dasdasd")
      console.log(response)
      if (response['respuesta'].status < 300) {
        this.generatedJSON = response['info']
        this.successModalShow()
      } else {
        this.errorTitle = 'Error ' + response['respuesta'].status;
        this.errorDescription = response['respuesta'].statusText;
        $('.toast').toast({ delay: 7000 });
        $('.toast').toast('show');
      }
    },
    toggleCheckbox(endpoint) {
      console.log(endpoint);
      switch (endpoint) {
        case 'checkboxNodes':
          this.checkboxNodes = !this.checkboxNodes;
          this.$emit('setCheckboxVal', this.checkboxNodes);
          break;
        case 'checkboxKeywords':
          this.checkboxKeywords = !this.checkboxKeywords;
          this.$emit('setCheckboxVal', this.checkboxKeywords);
          break;
        case 'checkboxNoFilters':
          this.checkboxNoFilters = !this.checkboxNoFilters;
          this.$emit('setCheckboxVal', this.checkboxNoFilters);
          break;
        case 'checkboxFilters':
          this.checkboxFilters = !this.checkboxFilters;
          this.$emit('setCheckboxVal', this.checkboxFilters);
          break;
        case 'checkboxAsins':
          this.checkboxAsins = !this.checkboxAsins;
          this.$emit('setCheckboxVal', this.checkboxAsins);
          break;
        case 'checkboxSalesPriceHistory':
          this.checkboxSalesPriceHistory = !this.checkboxSalesPriceHistory;
          this.$emit('setCheckboxVal', this.checkboxSalesPriceHistory);
          break;
        case 'checkboxSettleDates':
          this.checkboxSettleDates = !this.checkboxSettleDates;
          this.$emit('setCheckboxVal', this.checkboxSettleDates);
          break;
        case 'checkboxExtraProduct':
          this.checkboxExtraProduct = !this.checkboxExtraProduct;
          this.$emit('setCheckboxVal', this.checkboxExtraProduct);
          break;
        case 'checkboxBullets':
          this.checkboxBullets = !this.checkboxBullets;
          this.$emit('setCheckboxVal', this.checkboxBullets);
          break;
        case 'checkboxTexto':
          this.checkboxTexto = !this.checkboxTexto;
          this.$emit('setCheckboxVal', this.checkboxTexto);
          break;
        case 'checkboxAtributos':
          this.checkboxAtributos = !this.checkboxAtributos;
          this.$emit('setCheckboxVal', this.checkboxAtributos);
          break;
        case 'checkboxBuybox':
          this.checkboxBuybox = !this.checkboxBuybox;
          this.$emit('setCheckboxVal', this.checkboxBuybox);
          break;
        case 'checkboxReasonBuybox':
          this.checkboxReasonBuybox = !this.checkboxReasonBuybox;
          this.$emit('setCheckboxVal', this.checkboxReasonBuybox);
          break;
        case 'checkboxReviews':
          this.checkboxReviews = !this.checkboxReviews;
          this.$emit('setCheckboxVal', this.checkboxReviews);
          break;
        case 'checkboxSummary':
          this.checkboxSummary = !this.checkboxSummary;
          this.$emit('setCheckboxVal', this.checkboxSummary);
          break;
        case 'checkboxComments':
          this.checkboxComments = !this.checkboxComments;
          this.$emit('setCheckboxVal', this.checkboxComments);
          break;
        case 'checkboxNotify':
          this.checkboxNotify = !this.checkboxNotify;
          this.$emit('setCheckboxVal', this.checkboxNotify);
          break;
        case 'checkboxCoupons':
          this.checkboxCoupons = !this.checkboxCoupons;
          this.$emit('setCheckboxVal', this.checkboxCoupons);
          break;
        case 'checkboxKeywordSearchHistory':
          this.checkboxKeywordSearchHistory =
            !this.checkboxKeywordSearchHistory;
          this.$emit('setCheckboxVal', this.checkboxKeywordSearchHistory);
          break;
      }
    },
    insertNode(idName, marketplace, Family1, Family2, Family3, Family4, Family5, Nickname) {
      if (idName != '' && Nickname != '' && marketplace != '') {
        if (!this.nodesListData.some((x) => idName === x)) {
          this.nodesListData.push(marketplace + '-' + idName + '-' + Family1 + '-' + Family2 + '-' + Family3 + '-' + Family4 + '-' + Family5 + '-' + Nickname);
        }
      } else {
        this.errorAddingNode();
      }
    },
    insertKeyword(idName, marketplace, Family1, Family2, Family3, Family4, Family5, x, selectedBrand, selectedDepartment, selectedPriceMin, selectedPriceMax, selectedBrandName, selectedDepartmentName) {
      if (idName != '' && marketplace != '') {
        if (!this.keywordsListData.some((x) => idName === x)) {
          this.keywordsListData.push(marketplace + '-' + idName + '-' + Family1 + '-' + Family2 + '-' + Family3 + '-' + Family4 + '-' + Family5 + '-' + selectedBrand + '-' + selectedDepartment + '-' + selectedPriceMin + '-' + selectedPriceMax + '-' + selectedBrandName + '-' + selectedDepartmentName);
        }
        console.log( this.keywordsListData);
      } else {
        this.errorAddingNode();
      }
    },
    insertAsin(idName, marketplace, Family1, Family2, Family3, Family4, Family5) {
      if (idName != '') {
        if (!this.asinsListData.some((x) => idName === x)) {
          this.asinsListData.push(marketplace + '-' + idName + '-' + Family1 + '-' + Family2 + '-' + Family3 + '-' + Family4 + '-' + Family5);
        }
      }
    },
    nodeParseFile(event) {
      const file = event.target.files[0];
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        encoding: "ISO-8859-1",
        complete: function (results) {
          this.content = results;
          this.parsed = true;
          this.content.data.forEach((idObject) => {
            if (idObject.IDNodo != undefined && idObject.Marketplace != undefined && idObject.Familia1 != undefined && idObject.Familia2 != undefined && idObject.Familia3 != undefined && idObject.Familia4 != undefined && idObject.Familia5 != undefined && idObject.Nickname != undefined && idObject.Nickname != "") {
              if (!this.nodesListData.some((x) => idObject.Marketplace + '-' + idObject.IDNodo + '-' + idObject.Familia1 + '-' + idObject.Familia2 + '-' + idObject.Familia3 + '-' + idObject.Familia4 + '-' + idObject.Familia5 + '-' + idObject.Nickname === x)) {
                this.nodesListData.push(idObject.Marketplace + '-' + idObject.IDNodo + '-' + idObject.Familia1 + '-' + idObject.Familia2 + '-' + idObject.Familia3 + '-' + idObject.Familia4 + '-' + idObject.Familia5 + '-' + idObject.Nickname);
              }
            } else {
              this.errorImportCSV();
            }
          });
          this.nodesListData = JSON.parse(JSON.stringify(this.nodesListData));
        }.bind(this),
      });
      document.getElementById('file').value = '';
    },
    keywordsParseFile(event) {
      console.log("keywordsParseFile se ha ejecutado")
      const file = event.target.files[0];
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        encoding: "ISO-8859-1",
        complete: function (results) {
          this.content = results;
          this.parsed = true;
          this.content.data.forEach((idObject) => {
            if (idObject.Keyword != undefined && idObject.Marketplace != undefined && idObject.Familia1 != undefined && idObject.Familia2 != undefined && idObject.Familia3 != undefined && idObject.Familia4 != undefined && idObject.Familia5 != undefined) {
              if (!this.keywordsListData.some((x) => idObject.Marketplace + '-' + idObject.Keyword + '-' + idObject.Familia1 + '-' + idObject.Familia2 + '-' + idObject.Familia3 + '-' + idObject.Familia4 + '-' + idObject.Familia5 + '-' + idObject.Nickname === x)) {
                this.keywordsListData.push(idObject.Marketplace + '-' + idObject.Keyword + '-' + idObject.Familia1 + '-' + idObject.Familia2 + '-' + idObject.Familia3 + '-' + idObject.Familia4 + '-' + idObject.Familia5);
              }
            } else {
              this.errorImportCSV();
            }
          });
          this.keywordsListData = JSON.parse(JSON.stringify(this.keywordsListData));
        }.bind(this),
      });
    },
    asinsParseFile(event) {
      const file = event.target.files[0];
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        encoding: "ISO-8859-1",
        complete: function (results) {
          this.content = results;
          this.parsed = true;
          this.content.data.forEach((idObject) => {
            if (idObject.ASIN != undefined && idObject.Marketplace != undefined && idObject.Familia1 != undefined && idObject.Familia2 != undefined && idObject.Familia3 != undefined && idObject.Familia4 != undefined && idObject.Familia5 != undefined) {
              if (!this.asinsListData.some((x) => idObject.Marketplace + '-' + idObject.ASIN + '-' + idObject.Familia1 + '-' + idObject.Familia2 + '-' + idObject.Familia3 + '-' + idObject.Familia4 + '-' + idObject.Familia5 + '-' + idObject.Nickname === x)) {
                this.asinsListData.push(idObject.Marketplace + '-' + idObject.ASIN + '-' + idObject.Familia1 + '-' + idObject.Familia2 + '-' + idObject.Familia3 + '-' + idObject.Familia4 + '-' + idObject.Familia5);
              }
            }else{
              this.errorImportCSV();
            }
          });
          this.asinsListData = JSON.parse(JSON.stringify(this.asinsListData));
        }.bind(this),
      });
    },
    errorImportCSV() {
      this.errorTitle = 'Formato de CSV no válido';
      this.errorDescription =
        'Es necesario que el CSV tenga una estructura determinada, recuerda validar la estructura y que el Nickname/Nombre de pasillo no sea vacío en caso de añadir un pasillo';
      $('.toast').toast({ delay: 5000 });
      $('.toast').toast('show');
    },
    errorAddingNode() {
      this.errorTitle = 'Algún campo no es correcto';
      this.errorDescription =
        'Recuerda validar la estructura y que el Nickname/Nombre de pasillo no sea vacío al añadir un pasillo';
      $('.toast').toast({ delay: 5000 });
      $('.toast').toast('show');
    },
    onDeleteNode(category_id) {
      console.log(category_id);
      this.nodesListData = this.nodesListData.filter((x) => x !== category_id);
    },
    onDeleteKeyword(keyword) {
      this.keywordsListData = this.keywordsListData.filter((x) => x !== keyword);
    },
    onDeleteAsin(asin) {
      this.asinsListData = this.asinsListData.filter((x) => x !== asin);
    },
    onDeleteMail(mail) {
      this.mailsToSend = this.mailsToSend.filter((x) => x !== mail);
    },
    onNodeMarketplaceChange() {
      var data = '';
      $('select option:selected').each(function () {
        $('#nodeMarketplaceButtton').html($(this).text());
        console.log($(this).text());
        data = $(this).text();
      });
      this.nodeMarketplace = data;
    },
    onKeywordsMarketplaceChange() {
      var data = '';
      $('select option:selected').each(function () {
        $('#keywordMarketplaceButtton').html($(this).text());
        console.log($(this).text());
        data = $(this).text();
      });
      this.keywordMarketplace = data;
    },
    onAsinMarketplaceChange() {
      var data = '';
      $('select option:selected').each(function () {
        $('#asinMarketplaceButtton').html($(this).text());
        console.log($(this).text());
        data = $(this).text();
      });
      this.asinMarketplace = data;
    },
    onDateChange(selectedDate) {
      this.sampleDate = selectedDate;
      console.log(this.sampleDate)
    },
    modalShow() {
      if (this.userToken != '')
        $("#mi-modal").modal('show');
    },
    modalClose() {
      $("#mi-modal").modal('hide');
    },
    successModalShow() {
      $("#success-modal").modal('show');
    },
    successModalClose() {
      $("#success-modal").modal('hide');
    },
    addMail() {
      this.mailsToSend.push(this.inputMail);
      if (this.inputMail.trim() !== '') {
        if (this.inputMailString !== '') {
          this.inputMailString += ';';
        }
        this.inputMailString += this.inputMail.trim();
        this.inputMail = '';
      }
    },
    addSettleDate() {
      this.settleDates.push(this.settleDatesInput);
      if (this.settleDatesInput.trim() !== '') {
        if (this.settleDatesString !== '') {
          this.settleDatesString += ',';
        }
        this.settleDatesString += this.settleDatesInput.trim();
        this.settleDatesInput = '';
      }
    },
    downloadGenerateJSON() {
      const contenidoJSONString = JSON.stringify(this.generatedJSON);
      const blob = new Blob([contenidoJSONString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'reporte_generado.json';
      a.click();
      URL.revokeObjectURL(url);
    },
    getReportClients() {
      //axios.get('https://baipmarket-dev.brainycommerce.com/api/clients/')
      axios.get('https://baipmarket-prod.brainycommerce.com/api/clients/') // Cambia la URL a la que corresponda
      //axios.get('http://127.0.0.1:8000/api/clients/')
        .then(response => {
          if (response.data['Status'] == 200) {
            this.clients = response.data['data']
            return response.data['data']
          } else {
            this.clients = [['Cliente interno de brainy']]
          }
        })
        .catch(error => {
          console.error('Error al obtener el CSRF token:', error);
        });
    },
    abrirDocumentacionNuevaPestana() {
      const nuevaURL = 'https://www.notion.so/Alta-de-Informes-96b2c113d01b41d8aa35d5b3923d1706?pvs=4';
      // Abre una nueva pestaña con la URL
      window.open(nuevaURL);
    }
  }
};
$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});
</script>

<style scoped>
.row {
  margin: 0;
}

.home {
  padding-top: 4%;
  padding-left: 4%;
  padding-right: 4%;
  height: 100vh;
  overflow-y: scroll;
}

.col-12 {
  padding-left: 0%;
}

.name-input {
  padding-left: 0%;
}

.marketplace-input {
  padding-right: 0%;
}

.inputs-container {
  max-height: 93vh;
  padding-left: 0% !important;
  padding-right: 0%;
}

.inputs-title {
  text-align: start;
  font-size: 23px;
  font-weight: 800;
  padding-top: 30px;
  padding-bottom: 0px;
}

.inputs-title-less {
  text-align: start;
  font-size: 23px;
  font-weight: 800;
  padding-top: 10px;
  padding-bottom: 0px;
}

.inputs-title-no-padding {
  text-align: start;
  font-size: 23px;
  font-weight: 800;
  padding-bottom: 0px;
}

.inputs-sub-title {
  text-align: start;
  font-size: 18px;
  font-weight: 800;
  padding-top: 15px;
  padding-bottom: 0px;
}

.insert-input-container {
  padding-right: 0%;
  padding-left: 0%;
  margin-top: 0px;
}

.report-form-container {
  padding-top: 20px;
}

.form-control {
  color: #133641;
  border-color: #133641;
  border-radius: 8px;
  padding: 23px;
}

.inputs-list-master-main-container {
  background-color: #f8f8f8;
  width: 27vw;
  border-radius: 7px;
  min-height: 79vh;
  max-height: 600px;
  position: fixed;
  right: 4%;
  margin-top: 20px;
  overflow-y: auto;
  list-style: none;
}

.inputs-list-master-container {
  height: 79vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.inputs-list-master-container ul {
  height: calc(100% - 20px);
  overflow-y: auto;
}

ul {
  list-style: none;
  /* Elimina los puntos de la lista */
}

.masterrow {
  margin-right: 0px;
  margin-left: 0px;
  display: flex;
  justify-content: space-between;
}

.home {
  height: 100vh;
}

.title {
  text-align: start;
  font-size: 30px;
  font-weight: 800;
  padding-bottom: 20px;
}

.date-selector {
  display: flex;
  float: left;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.date-selector-row {
  margin-top: 10px;
}

.date-selector-margin {
  margin-left: 2%;
  width: 100%;
}

@media (min-width: 1299px) {
  .toggle-group-colum {
    margin-left: 3%;
  }
}

.create-report {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 45px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.create-report-help {
  font-weight: 600;
  text-align: center;
  padding-bottom: 35px;
  text-decoration: dashed;
}

.create-report-button {
  background-color: #f18a00;
  font-weight: 700;
  font-size: 18px;
  color: white;
  padding: 10px 25px;
  width: fit-content;
  height: 50px;
  margin-left: 15px;

}

.filters-button {
  background-color: #f18a00;
  font-weight: 700;
  font-size: 18px;
  color: white;
  padding: 10px 25px;
  width: 200px;
  height: 50px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center
}

.create-report-button:hover {
  transition: 0.3s;
  -webkit-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
}

.filters-button:hover {
  transition: 0.3s;
  -webkit-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 20px -12px rgba(0, 0, 0, 0.75);
}

.btn:focus {
  outline: none;
  box-shadow: none;
  color: #f18a00;
}

.no-data-div {
  background-image: url('../assets/nodata.png');
  height: 73vh;
  width: 20vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.nodes-title-list {
  font-size: 26px;
  font-weight: 800;
  text-align: start;
  padding-left: 5%;
  padding-bottom: 2%;
  padding-top: 15px;
}

.help-icon {
  background-image: url('../assets/help.png');
  background-size: cover;
  height: 25px;
  width: 25px;
  margin-right: 8px;
}

.plus-icon {
  background-image: url('../assets/plus2.png');
  background-size: cover;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.product-sample-title {
  font-size: 20px;
  text-align: start;
}

.product-sample-title-container {
  display: flex;
  width: 100%;
  padding-top: 15px;
}

.product-sample-container {
  padding-top: 18px;
}

.basic-product-sample-options {
  text-align: start;
  padding-top: 10px;
}

.basic-product-sample-options-divider {
  border-top: solid rgb(223, 221, 221) 1.5px;
  border-radius: 3px;
  display: grid;
  width: 90%;
}

.inside-basic-product-sample-options-divider {
  width: 300px;
  border-top: solid rgb(223, 221, 221) 1.5px;
  border-radius: 3px;
  display: grid;
}

.basic-product-sample-toggles {
  padding-top: 0px;
}

.keywords-sample-container {
  padding-top: 18px;
}

.left-sample-container {
  padding-left: 0px;
}

.right-sample-container {
  padding-right: 0px;
  padding-left: 0px;
}

.toogles-container {
  width: 100%;
}

.report-help-container {
  background-color: #f1890055;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 280px;
  border-radius: 10px;
  transition: 0.3s;
}

.report-help-container:hover {
  transition: 0.3s;
  -webkit-box-shadow: 6px 6px 15px -14px rgba(0, 0, 0, 0.85);
  -moz-box-shadow: 6px 6px 15px -14px rgba(0, 0, 0, 0.85);
  box-shadow: 6px 6px 15px -14px rgba(0, 0, 0, 0.85);
}

.report-help-label {
  color: #133641;
  margin-bottom: 0px;
  padding-right: 10px;
  font-weight: 600;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.report-parent-type-container {
  margin-top: 15px;
}

.report-type-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
}

.dropdown {
  width: 100%;
}

.type-dropdown-button {
  color: #133641;
  border: solid #133641 1.5px;
  border-radius: 7px;
  background-color: white;
  height: 49px;
  width: 100%;
  text-align: start;
}

.type-dropdown-button-refinement {
  margin-top: 5px;
  width: auto;
  color: #133641;
  border: solid #133641 1.5px;
  border-radius: 7px;
  background-color: white;
  height: 49px;
  text-align: start;
}

.type-dropdown-button-v2 {
  color: #133641;
  border: solid #133641 1.5px;
  border-radius: 7px;
  background-color: white;
  padding-inline: 23px;
  margin-bottom: 6px;
  text-align: start;
}

.v-calendar {
  width: 100% !important;
  margin: 0px;
}

.final-date-input-container {
  padding-right: 0px;
}

.loadingMsg {
  font-size: 30px;
}

.disabled-dropdown {
  background-color: #f6f6f6;
  border: solid #687d8446 1.5px;
}

.sub-toggle-input {
  padding-left: 20px;
}

.sub-sub-toggle-input {
  padding-left: 40px;
}

.sub-text-input {
  width: 250px;
  height: 40px;
  margin-left: 20px;
}

.sub-sub-text-input {
  width: 250px;
  height: 40px;
  margin-left: 40px;
}

.sub-sub-sub-text-input {
  width: 250px;
  height: 40px;
  margin-left: 60px;
}

.max-price-input {
  margin-left: 5px;
}

.margin-product-sample-families {
  margin-top: 0px;
}

.flex-start {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 4%;
}

.margin-families-form {
  margin-top: 8px;
}

.titulo-modal {
  color: #133641;
  font-weight: 700;
}

.modal-color-button {
  color: white;
  background-color: #f18a00;
  font-weight: 700;
}

.modal-font-button {
  color: #133641;
  font-weight: 700;
}

.settle-dates-input {
  margin-bottom: 6px;
}

.input-wrapper {
  position: relative;
  width: max-content;
}

.input-with-icon {
  padding-right: 30px;
  /* Ajusta este valor según el tamaño del icono */
}

.filter-marketplace {
  margin-left: 20px;
}

.input-icon {
  position: absolute;
  top: 2px;
  right: -47px;
  font-size: 20px;
  /* Ajusta el tamaño del icono aquí */
  color: #ccc;
  background-color: transparent;
  border: none;
  /* Puedes ajustar el color del icono aquí */
}

.display-flex-default {
  display: flex;
}

.mailItemList {
  width: 350px;
  margin-left: 48px;
  margin-right: 10px;
  margin-top: 10px;
}

.delivery-freq-container {
  margin-bottom: 15px;
}

.sample-list {
  list-style: none;
}

.sample-help {
  margin-left: 10px;
}

.title-basic-product-sample-text {
  text-align: start;
  color: #133641;
  margin-left: 20px;
  margin-top: 8px;
  margin-bottom: 6px;
  font-size: 17px;
  left: 0%;
  top: 0%;
}

.basic-product-sample-text {
  text-align: start;
  color: #9a9999;
  margin-left: 20px;
  left: 0%;
  top: 0%;
}

.sub-basic-product-sample-text {
  padding-left: 20px;
}

.padding-20-left {
  margin-left: 20px !important;
}

.margin-10-bottom {
  margin-bottom: 20px;
}

.margin-8-top {
  margin-top: 9px;
}

.margin-3-top {
  margin-top: 3px;
}

.keywords-help-icon-container {
  display: flex;
  align-items: center;
}

#multiple {
  width: 100%;
  height: 130px;
  background-color: #f7f7f7;
  border-color: transparent;
}

.margin-top-price {
  margin-top: 5px;
}

#multiple option {
  text-align: center;
  padding: 3px;
}
</style>
